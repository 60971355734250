import React from 'react'
//import API from './API'
import Script from '@gumgum/react-script-tag';
import { getToken } from '../../../tokens';

// import { create } from 'apisauce'

// const publicKey = "pk_test_Mmne3yRTKQFNzZWO"
// const publicKey = "pk_meMqojzUwtrEJBNd"
// const publicKey = "pk_RnwYoGOC7c1Wo8Wp"

// import pagarme from './pagarme_browser'

// const api = create({
//     baseURL: `https://api.pagar.me/core/v5`,
// });
/*
    1. Inclua a biblioteca MercadoPago.js
    2. Adicione o formulário de pagamento
    3. Configure sua chave pública
    4. Obtenha os dados para seu formulário
    5. Crie o token do cartão
*/

const PagarMe = () => {
    // Obtenha os tipos de documento
    const getIdentificationTypes = async () => {
        let tipos = window.Mercadopago.getIdentificationTypes();
        //Logger.log("{ getIdentificationTypes }", tipos)
        return tipos
    }

    // Obtenha o método de pagamento do cartão
    const getPaymentMethod = async (cardNumber) => {
        return new Promise((resolve, reject) => {
            if (cardNumber.length >= 6) {
                let bin = cardNumber.substring(0, 6);
                window.Mercadopago.getPaymentMethod({
                    "bin": bin
                }, (status, response) => {
                    if (status == 200) {
                        let paymentMethod = response[0]
                        resolve(paymentMethod)
                    } else {
                        reject(`Erro: ${response}`)
                    }
                });
            }
        })
    }

    // Crie o token do cartão
    const getCardToken = async (docNumber, cardholderName, cardNumber, cardExpiration, securityCode) => {
        // return new Promise(async (resolve, reject) => {
        try {
            let dados = {
                card: {
                    holder_document: docNumber?.split(".")?.join("")?.split("-")?.join(""),
                    holder_name: cardholderName,
                    exp_month: cardExpiration.split("/")?.[0],
                    exp_year: cardExpiration.split("/")?.[1],
                    number: cardNumber.split(" ").join(""),
                    cvv: securityCode,
                    // brand: "BRAND"
                },
                type: "card"
            }

            let token = await getToken("pagar.me", dados)

            return token

            // let { ok, data } = await api.post("/tokens", dados, {
            //     params: { appId: publicKey },
            //     headers: {
            //         "accept": "application/json",
            //         "content-type": "application/json"
            //     }
            // })

            // if (!ok)
            //     throw "Erro na geração do Card Token"

            // resolve(data)

            // pagarme.client.connect({ api_key: publicKey })
            //     .then(client => client.cards.create({
            //         card_number: cardNumber.split(" ").join(""),
            //         card_holder_name: cardholderName,
            //         card_expiration_date: cardExpiration.split("/").join(""),
            //         card_cvv: securityCode,
            //     }))
            //     .then(card => console.log(card.id))
        } catch (error) {
            console.error("ERROR getCardToken PAGARME", error)
            // reject(error)
            throw error
        }
        // })
    }

    const initPosLoad = () => {
        //let publicKey = "TEST-1053eb4e-bd65-46bf-b55d-577af2e5f0ec" // Teste
        let publicKey = "APP_USR-4e3ac8c1-6abb-4ac0-82d9-fc05ce353ae7" // Produção
        window.Mercadopago.setPublishableKey(publicKey);

        let tipos = window.Mercadopago.getIdentificationTypes();
        //Logger.log("window.Mercadopago.getIdentificationTypes()", tipos)
    }

    const getScript = () => {
        return <Script
            src="https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js"
            type="text/javascript"
            onLoad={() => { initPosLoad() }}
            onError={() => { alert("error mercadoPago") }}
            async
        />
    }

    return { getScript, getIdentificationTypes, getPaymentMethod, getCardToken }
}
export default PagarMe
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 12,
        borderBottomColor: 'rgba(102, 102, 102, 0.35)',
        borderBottomWidth: 1
    },
    areaControleQuantidade: {
        flexDirection: 'row',
        alignSelf: 'center',
        backgroundColor: '#E3E3E3'
    },
    botaoMaisMenos: {
        backgroundColor: "#04AD8A",
        padding: 5,
        borderRadius: 3
    },
    botaoDisabled: {
        backgroundColor: '#046d55'
    }
});

export default styles
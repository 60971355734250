import { StyleSheet, Dimensions } from 'react-native'
import { isBrowser, isMobile } from "react-device-detect";
import Colors from '../../values/colors'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20
    },
    divisoria1: Object.assign({},
        {
            paddingTop: 6,
            paddingLeft: 6,
            paddingBottom: 6,
            justifyContent: 'center',
            alignItems: 'center'
        }, isMobile ? {
            marginBottom: 30
        } : {
            flex: 1,
            height: '100%',
            borderRightColor: 'rgba(102, 102, 102, 0.35)',
            borderRightWidth: 1,
        }
    ),
    divisoria2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    icone: {
        margin: 5,
        borderWidth: 1,
        borderColor: Colors.primary,
        width: 40,
        height: 40,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    sombra: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    }
});

export default styles
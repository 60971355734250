import React, { useEffect, useState } from 'react'

import { Text, View } from 'react-native'

import SelectAutocomplete from '../Form/SelectAutocomplete'
import API from '../../services/API'

export default function CitySelect({
    name,
    label = "Cidade",
    placeholder = "Escolha a cidade",
    emptyText = "Nenhuma cidade encontrada"
}) {
    const [city, setCity] = useState()
    const [text, setText] = useState("")
    const [cities, setCities] = useState([])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (text.length >= 3)
                search()
        }, 1000);
        return () => clearTimeout(timer);
    }, [text])

    const search = async () => {
        console.log("search", text)
        try {
            let { data } = await API.get(`/config/cidades/${text}`)

            setCities(() => data.map(city => ({ id: city._id, title: `${city.nome} / ${city.estado_sigla}` })))
        } catch (error) {

        }
    }

    return (
        <View>
            <SelectAutocomplete
                name={name}
                label={label}
                data={cities}
                // widthBySide={30}
                placeholder={placeholder}
                // placeholderTextColor={"green"}
                // borderBottomColor={"green"}
                borderRadius={7}
                // borderBottomWidth={2}
                borderRadiusList={10}
                listElevation={7}
                listBackgroundColor={"white"}
                listTextColor={"black"}
                parentCallback={({ id }) => setCity(id)}
                onChangeText={(text) => setText(() => text)}
                emptyText={emptyText}
            />
        </View>
    )
}
import React from "react";

import LightboxCheckout from "./src/pages/LightboxCheckout";

import Providers from "./src/providers";

export default function App() {
  return (
    <Providers>
      <LightboxCheckout />
    </Providers>
  );
}

import { StyleSheet, Dimensions } from "react-native";
const { width, height } = Dimensions.get("window");
import { isBrowser, isMobile } from "react-device-detect";

const styles = StyleSheet.create({
  container: Object.assign(
    {},
    isMobile
      ? {
          flex: 1,
          minHeight: "100%",
        }
      : {
          flex: 1,
          flexDirection: "row",
        }
  ),
  divisoria1: {
    backgroundColor: "#ECECEC",
    flex: 1,
    height: "100%",
    borderRightColor: "rgba(102, 102, 102, 0.35)",
    borderRightWidth: 1,
    paddingTop: 6,
    paddingLeft: 6,
    paddingBottom: 6,
  },
  divisoria2: {
    flex: 1,
  },
});

export default styles;

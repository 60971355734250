export const ValidCPF = (cpf) => {
    try {
        if (cpf == undefined) return false

        cpf = cpf.replace(/[^\d]+/g, '');

        var Soma;
        var Resto;
        Soma = 0;
        if (cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111") return false;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(cpf.substring(9, 10))) return false;

        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(cpf.substring(10, 11))) return false;
        return true;
    } catch (err) { return false }
}

export const ValidCelular = (celular) => {
    try {
        celular = celular.replace(/[^\d]+/g, '');

        if (celular.length < 10 || celular.length > 11) return false;

        return true;
    } catch (err) { return false }
}

export const ValidDataAntiga = (data) => {
    try {
        if (data.length != 10) return false

        let [dia, mes, ano] = [0, 0, 0]

        if (data[2] == "/") [dia, mes, ano] = data.split("/")
        else if (data[4] == "-") [ano, mes, dia] = data.split("-")
        else return false

        if (dia > 31 || mes > 12 || new Date(`${ano}-${mes}-${dia}`) > new Date()) return false

        return true
    } catch (err) { return false }
}
import React, { useState, useEffect, useContext } from 'react'
import { View, Text } from 'react-native'

import styles from './styles'
import { dateDiff } from '../../helpers/Date'

// import jwt from 'expo-jwt'
import CompraContext from '../../contexts/compra'
import LightboxContext from '../../contexts/lightbox'

const Temporizador = () => {
    const { setPagina, setError } = useContext(LightboxContext)
    const { modoPagamento, dataHoraLimite } = useContext(CompraContext)

    const [tempo, setTempo] = useState(null)
    const [relogio, setRelogio] = useState(null)

    // const [dataHoraLimite, setDataHoraLimite] = useState(null)

    // useEffect(() => {
    //     try {
    //         if (tokenReserva) {
    //             console.log("tokenReserva", tokenReserva)
    //             let decoded = jwt.decode(tokenReserva)
    //             // console.log("DECODEDDDDD", decoded)

    //             let { datahora_limite } = decoded
    //             // let datahora_limite = "2023-01-30T20:46:40.037+00:00"

    //             setDataHoraLimite(datahora_limite)
    //         }
    //     } catch (error) {
    //         console.error("errooooo", error)
    //     }
    // }, [])

    useEffect(() => {
        if (modoPagamento != null && dataHoraLimite)
            startRelogio(tempo)
    }, [dataHoraLimite, modoPagamento])

    const startRelogio = () => {
        setTimeout(() => {
            let tempoAtualizado = dateDiff(dataHoraLimite, new Date(), "seconds")

            // console.log("tempoAtualizado", tempoAtualizado)

            if (tempoAtualizado > 0) {
                setTempo(tempoAtualizado - 1)

                let min = Math.floor(tempoAtualizado / 60)
                let seg = tempoAtualizado % 60

                if (min < 10) min = `0${min}`
                if (seg < 10) seg = `0${seg}`

                setRelogio(() => ({ min, seg }))

                startRelogio()
            } else {
                if (modoPagamento)
                    setError(true, "O tempo para o pagamento expirou")
                else
                    setPagina('ingressos')
            }
        }, 1000)
    }

    return (
        <View>
            {relogio && relogio.min == 0 && relogio.seg == 0 ?
                <Text style={styles.text}>Reserva expirou</Text> :
                <Text style={styles.text}>Finalize em {relogio ? `${relogio.min}:${relogio.seg}` : "--:--"} {modoPagamento}</Text>
            }
        </View>
    )
}

export default Temporizador
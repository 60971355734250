import React, { useRef, useEffect, useState, useContext } from "react";
import {
  View,
  Text,
  Button,
  Image,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { Form } from "@unform/mobile";
import Input from "../../components/Form/input";
import { isBrowser, isMobile } from "react-device-detect";

import * as Linking from "expo-linking";

import LightboxContext from "../../contexts/lightbox";

import MyUniverse from "../../../assets/images/my-universe.svg";

import styles from "./styles";
import Colors from "../../values/colors";

import * as Yup from "yup";
import API from "../../services/API";
import AuthContext from "../../contexts/auth";
import CompraContext from "../../contexts/compra";
import { TouchableOpacity } from "react-native-gesture-handler";
import Avatar from "../../components/Avatar";

const Login = () => {
  const { pagina, setPagina } = useContext(LightboxContext);
  const { pessoa, setPessoa, setTokenAuth, logout } = useContext(AuthContext);
  const { reservarIngressos } = useContext(CompraContext);

  const formRef = useRef(null);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);

  useEffect(() => {}, []);

  const handleSubmit = async (data) => {
    //console.log(`handleSubmit`, data)
    try {
      setErrorLogin(null);
      setLoadingLogin(true);
      const schema = Yup.object().shape({
        login: Yup.string().required("Campo obrigatório"),
        senha: Yup.string().required("Campo obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current.setErrors({});

      let dados = {
        login: data.login,
        senha: data.senha,
        tipo: "email",
      };

      let loginResponse = await API.post("/auth/sessao/login", dados);
      let tokenSessao = loginResponse.data;

      API.setHeader("x-access-token", tokenSessao.token);
      let pessoaResponse = await API.get("/pessoas/sessao");
      let pessoa = pessoaResponse.data;

      setTokenAuth(tokenSessao.token);
      setPessoa(pessoa);

      setPagina("selecionarTitular");
      // reservarIngressos()
    } catch (error) {
      setLoadingLogin(false);
      //console.log("ERRO", error)
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        setErrorLogin(error.data ? error.data : "Erro no login");
      }
    }
  };

  const sim = async () => {
    setPagina("selecionarTitular");
    // reservarIngressos()
  };

  console.log(Dimensions.get("window"));

  const getDimensiosImage = ({ width, height }) => {
    let scale = 1;

    if (height < 900) scale = 1.25;
    if (height < 800) scale = 1.5;
    if (height < 700) scale = 1.75;
    if (height < 600) scale = 2;
    if (height < 500) scale = 3.5;
    if (height < 400) scale = 4;

    let finalWidth = 586.2920625 / scale;
    let finalHeight = 480.337665 / scale;

    return {
      width: finalWidth,
      height: finalHeight,
    };
  };

  return (
    <View style={styles.container}>
      {!isMobile && (
        <View
          style={{ flex: 2, justifyContent: "center", alignItems: "center" }}
        >
          <Image
            style={{
              flex: 1,
              ...getDimensiosImage(Dimensions.get("window")),
              maxWidth: "100%",
              maxHeight: "100%",
              resizeMode: "contain",
            }}
            source={{
              uri: MyUniverse,
            }}
          />
        </View>
      )}
      {pessoa && (
        <View
          style={{
            flex: 1,
            paddingHorizontal: 50,
            paddingTop: isMobile ? 20 : 0,
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 18, marginBottom: 30, textAlign: "center" }}>
            É você mesmo?
          </Text>

          <Avatar src={{ uri: pessoa?.path_foto }} size={96} />

          <Text style={{ fontSize: 24, marginTop: 20, textAlign: "center" }}>
            {pessoa.apelido || pessoa.nome}
          </Text>

          <View
            style={{
              marginTop: 30,
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <View style={{ width: "45%" }}>
              <Button title={"Não"} onPress={logout} color="tomato" />
            </View>
            <View style={{ width: "45%" }}>
              <Button title={"Sim"} onPress={sim} color="#04AD8A" />
            </View>
          </View>
        </View>
      )}
      {!pessoa && (
        <View
          style={{
            flex: 1,
            paddingHorizontal: 50,
            paddingTop: isMobile ? 20 : 0,
          }}
        >
          <Text style={{ fontSize: 18, marginBottom: 30, textAlign: "center" }}>
            Entre com a sua conta para continuar
          </Text>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="login"
              label="Login"
              placeholder="E-mail, CPF ou Celular"
            />
            <Input
              name="senha"
              label="Senha"
              placeholder="Digite sua senha"
              secureTextEntry={true}
            />

            <Button
              title={
                loadingLogin ? (
                  <ActivityIndicator
                    animating={true}
                    size="small"
                    color="#00ff00"
                  />
                ) : (
                  "Entrar"
                )
              }
              onPress={() => {
                formRef.current.submitForm();
              }}
              color="#04AD8A"
            />

            {errorLogin && (
              <Text
                style={{ color: "red", textAlign: "center", marginTop: 20 }}
              >
                {errorLogin}
              </Text>
            )}

            <Text
              style={{
                color: Colors.primary,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL("https://ipass.com.br/cadastrar").catch(
                    () => {
                      let json = {
                        action: "close-lightbox-checkout",
                        value: true,
                        href: "/cadastrar",
                      };

                      window.top.postMessage(JSON.stringify(json), "*");
                    }
                  )
                }
              >
                Não tenho conta
              </TouchableOpacity>
            </Text>
          </Form>
        </View>
      )}
    </View>
  );
};

export default Login;

import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    containerSelecionado: {
        backgroundColor: '#FFF',
        marginLeft: 6,
        marginTop: 6,
        marginBottom: 6,
        padding: 12,
        borderTopStartRadius: 10,
        borderBottomStartRadius: 10,
        borderColor: 'rgba(51, 51, 51, 0.35)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1
    },
    container: {
        backgroundColor: 'rgba(255, 255, 255,0.4)',
        marginLeft: 6,
        marginTop: 6,
        marginBottom: 6,
        padding: 12,
        borderTopStartRadius: 10,
        borderBottomStartRadius: 10,
        borderColor: 'rgba(51, 51, 51, 0.35)',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1
    },
    triangulo: {
        color: '#04AD8A',
        position: 'absolute',
        right: -4,
        transform: [{ rotate: '-90deg' }]
    }
});

export default styles
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        left: 0,
        top: 0,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#04AB89",
        width: "100%",
        height: "100%",
        zIndex: 1000
    },
    iframe: {
        flex: 1,
        // width: '100%',
    },
    closeButton: {
        position: "absolute",
        top: 20,
        right: 40
    }
});

export default styles;
import { StyleSheet } from 'react-native';
import { isBrowser, isMobile } from 'react-device-detect';

const styles = StyleSheet.create({
  container: Object.assign(
    {},
    isMobile
      ? {
          flex: 1,
          minHeight: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 20,
        }
      : {
          flex: 1,
          flexDirection: 'row',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 20,
        }
  ),
});

export default styles;

//import React from 'react'
import { create } from "apisauce";
//import { API_KEY } from '@env'
//import { AsyncStorage } from 'react-native'

// let anywhere = 'https://thingproxy.freeboard.io/fetch/'//'http://localhost:8000'
// let baseUrl = "https://api.ipass.com.br"//anywhere + 'https://0f8d-187-45-40-180.ngrok.io'
// let baseUrl = "https://4d1b-187-45-41-123.ngrok.io/" // Dev
let baseUrl = "https://homologacao.api.ipassingressos.com.br";
// let baseUrl = "http://localhost:3333"

export const defaultApplicationToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJpcGFzcyIsImludGVncmFjYW9faWQiOiIxNTc0YmRkYjc1Yzc4YTZmZDIyNTFkNjFlMjk5M2I1MTQ2MjAxMzE5IiwiaWF0IjoxNjE0NzA2MDE1fQ.RqWSS2AXc7ryhFMeXsSrY03mN4ny1Qo4vGj7qCNf8nw"

const API = create({
  baseURL: baseUrl,
  params: {
    token:
      defaultApplicationToken, // Token de autorização para utilização da API
  },
});

API.addResponseTransform((response) => {
  if (!response.ok) throw response;
});

API.addRequestTransform((request) => async () => {
  //console.log(`${request.method.toLocaleUpperCase()} - ${request.url}`)

  const token = await AsyncStorage.getItem(`x-access-token`);

  //console.log(`addRequestTransform`, token)

  // Montar cabeçalho
  if (token) request.headers[`x-access-token`] = token; // Token de sessão*/
  //if (token) API.setHeader("x-access-token", token)

  return request;
});

export default API;

import React, { useRef, useContext, useState, useEffect } from 'react'
import { View, Button, Text } from 'react-native'
import { Form } from '@unform/mobile'

import Input from '../../components/Form/input'
import InputMask from '../../components/Form/inputMask'
import styles from './styles'
import Colors from '../../values/colors'

import API from '../../services/API'

import * as Yup from 'yup'

import { validCPF, calcDateDiff } from "@renegoncalves/rg-helper"
import { validatePhone } from 'validations-br';

import moment from "moment"

// Contexts
import CompraContext from '../../contexts/compra'

const DefinirTitular = ({ ingresso, fecharDefinicaoTitular }) => {
    const { putIngresso } = useContext(CompraContext)
    const formRef = useRef(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        //console.log("DEFINITIR TITULAR -> ingresso", ingresso)
    }, [])

    const handleSubmit = async (data) => {
        try {
            setLoading(true)
            console.log(`handleSubmit`, data)

            formRef.current.setErrors({})

            //console.log("DEFINIR TITULAR", data)

            data.data_nascimento = data.data_nascimento.split("/").reverse().join("-")
            data.cpf = data.cpf.split(".").join("").split("-").join("")
            data.celular = data.celular.split("(").join("").split(")").join("").split("-").join("").split(" ").join("")

            const schema = Yup.object().shape({
                nome: Yup.string().test("nome-sobrenome", "Nome e sobrenome obrigatórios", (value) => value.split(" ").length > 1).required("Campo obrigatório!"),
                cpf: Yup.string().test("is-cpf", "CPF inválido", (value) => validCPF(value)).required("Campo obrigatório!"),
                celular: Yup.string().test("is-phone", "Celular inválido", (value) => validatePhone(value)).required("Campo obrigatório!"),
                email: Yup.string().email("E-mail inválido").required("Campo obrigatório!"),
                data_nascimento: Yup.string().test("is-date-valid", "Data inválida", (value) => {
                    const diff = calcDateDiff(value, moment(), "years")
                    return !isNaN(Date.parse(value)) && diff < 0 && diff > -120
                }).required("Campo obrigatório!"),
            })

            await schema.validate(data, {
                abortEarly: false
            })

            let dados = {
                ingresso_id: ingresso._id,
                ...data
            }

            let definicaoResponse = await API.post(`/ingressos/titulares`, dados)
            let ing = definicaoResponse.data

            putIngresso(ing)

            fecharDefinicaoTitular()

            setLoading(false)
        } catch (error) {
            console.log("ERRO", error)
            setError(error.data)
            setLoading(false)

            if (error instanceof Yup.ValidationError) {
                const errorMessages = {}

                error.inner.forEach(err => {
                    errorMessages[err.path] = err.message
                })

                formRef.current.setErrors(errorMessages)
            }
        }
    }

    return (
        <View style={styles.container}>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <Input name="nome" label="Nome" placeholder="Nome" styleInput={styles.input} />
                <InputMask type="cpf" name="cpf" keyboardType="numeric" label="CPF" placeholder="CPF" styleInput={styles.input} />
                <InputMask type="cel-phone" name="celular" keyboardType="numeric" label="Celular" placeholder="Celular" styleInput={styles.input} />
                <Input name="email" label="E-mail" placeholder="E-mail" styleInput={styles.input} />
                <InputMask type="custom" options={{ mask: "99/99/9999" }} name="data_nascimento" label="Data de Nascimento" placeholder="Data de Nascimento" styleInput={styles.input} />

                {error && <Text style={{ color: Colors.error, marginVertical: 10 }}>{error}</Text>}

                <View style={{ flex: 1, flexDirection: 'row' }}>
                    {!loading && <View style={{ flex: 1, paddingRight: 5 }}>
                        <Button color={Colors.cancel} title="Cancelar" onPress={() => { fecharDefinicaoTitular() }} />
                    </View>}
                    <View style={{ flex: 1, paddingLeft: 5 }}>
                        <Button style={{ flex: 1, width: '49%' }} color={Colors.success} title={loading ? "Definindo" : "Definir"} onPress={() => { !loading ? formRef.current.submitForm() : null }} />
                    </View>
                </View>
            </Form>
        </View >
    )
}

export default DefinirTitular
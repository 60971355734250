import React, { useEffect, useRef, useState } from 'react';
import { View, Text } from 'react-native'
import Picker, { PickerSelectProps } from 'react-native-picker-select';
import { useField } from '@unform/core';
import styles from './styles'
import Colors from '../../../values/colors';

export default function PickerSelect({ name, label, styleText, items, labelType, error, errorMessage, ...rest }) {
    if (!labelType) labelType = "outlined" // flat, outlined
    const pickerRef = useRef(null);
    const { fieldName, registerField, defaultValue = '', error: errorField } = useField(name);
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: pickerRef.current,
            getValue: ref => {
                return ref.props.value || '';
            },
            clearValue: ref => {
                ref.props.onValueChange(ref.props.placeholder.value);
            },
            setValue: (_, value) => {
                setSelectedValue(value);
            },
        });
    }, [fieldName, registerField]);
    return (
        <View style={errorField || errorMessage ? { borderColor: Colors.error, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {}}>
            {label && labelType == "flat" && <Text
                style={[{
                    color: "#152536",
                    fontSize: 14
                }, styleText]}
            >{label}</Text>}

            <View style={[styles.textInputView, (error || errorField) && {
                borderColor: Colors.error
            }]}>
                {label && labelType == "outlined" && <Text
                    style={[styles.labelInner, styleText]}
                >{label}</Text>}

                <Picker
                    ref={pickerRef}
                    value={selectedValue}
                    onValueChange={setSelectedValue}
                    items={items}
                    style={pickerStyle}
                    {...rest}
                />
            </View>

            {(error || errorField) && <View style={{ backgroundColor: Colors.error, padding: 5, borderBottomLeftRadius: 3, borderBottomRightRadius: 3, marginBottom: 10 }}>
                <Text style={[{ color: '#FFF', fontSize: 12 }]}>{errorMessage || errorField}</Text>
            </View>}
        </View>
    );
};

const pickerStyle = {
    inputWeb: {
        height: 40,
        borderWidth: 0,
        borderColor: "#CED4DA",
        padding: 10,
        borderRadius: 3
    }
}
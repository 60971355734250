import React, { useContext, useEffect } from "react";
import { ScrollView, View, Text, Button, Image } from "react-native";
import { isMobile } from "react-device-detect";

import ReconhecimentoFacial from "../../../assets/images/reconhecimento_facial.svg";

import styles from "./styles";

// Contexts
import AuthContext from "../../contexts/auth";
import LightboxContext from "../../contexts/lightbox";

const Reconhecimento = () => {
  const { tokenAuth } = useContext(AuthContext);
  const { pessoa, buscarPessoa, setReconhecimentoFeito } =
    useContext(AuthContext);
  const { setPagina, setReconhecimentoOpen } = useContext(LightboxContext);

  useEffect(() => {
    iniciarBuscaPessoa();
  }, []);

  useEffect(() => {
    if (pessoa?.face) {
      setReconhecimentoFeito(true);
      setReconhecimentoOpen(false);
      setPagina("confirmacao");
    }
  }, [pessoa]);

  const iniciarBuscaPessoa = async () => {
    try {
      let pessoa = await buscarPessoa();
      if (!pessoa?.face) {
        setTimeout(() => {
          iniciarBuscaPessoa();
        }, 5000);
      }
    } catch { }
  };

  const plataform = isMobile ? "mobile" : "web";

  return (
    <View style={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
      <ScrollView style={{ flex: 1, height: "100%" }}>
        <View style={styles.container}>
          <View style={styles.divisoria1}>
            <Image
              style={{
                width: 1074 / (isMobile ? 4 : 2.25),
                height: 739 / (isMobile ? 4 : 2.25),
                marginVertical: 30,
              }}
              source={{
                uri: ReconhecimentoFacial,
              }}
            />

            <Text
              style={{
                fontSize: 22,
                fontWeight: 600,
                textAlign: "center",
                marginTop: isMobile ? 20 : 0,
                marginBottom: 20,
              }}
            >
              Pagamento concluído, para confirmar sua compra faça o cadastro de
              reconhecimento facial para entrada no evento
            </Text>

            {isMobile ? (
              <Button
                title="Fazer cadastro"
                color="#04AD8A"
                onPress={() => setReconhecimentoOpen(true)}
              />
            ) : (
              <>
                <Text>Faça o cadastro abaixo:</Text>
                <iframe
                  src={pessoa?.faceURL}
                  style={{ width: "100%", height: "800px", border: 0 }}
                />
              </>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default Reconhecimento;

import React, { useContext } from "react";
import {
  SafeAreaView,
  View,
  Text,
  Image,
  TouchableHighlight,
  Linking,
} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

// Styles
import styles from "./styles";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import EscolhaIngressos from "../EscolhaIngressos";
import Pagamento from "../Pagamento";
import Reconhecimento from "../Reconhecimento";
import Confirmacao from "../Confirmacao";
import Loading from "../Loading";
import Erro from "../Erro";

import Temporizador from "../../components/Temporizador";
import Login from "../Login";
import LightboxContext from "../../contexts/lightbox";
import Processamento from "../Processamento";
import SelecionarTitular from "../SelecionarTitular";
import IframeReconhecimento from "../../components/IframeReconhecimento";

const Lightbox = () => {
  const { pagina, loading, error, fecharLightbox, isLightbox, showHeader, setReconhecimentoOpen, reconhecimentoOpen } = useContext(LightboxContext);

  return (
    <SafeAreaView style={styles.lightbox}>
      <IframeReconhecimento
        open={reconhecimentoOpen}
        close={() => setReconhecimentoOpen(false)}
      />

      <View
        style={{
          flexDirection: "row",
          alignSelf: "flex-end",
          marginRight: isLightbox ? "11%" : "10%",
          marginBottom: 10,
        }}
      >
        {pagina == "pagamento" && <Temporizador />}

        {isLightbox && (
          <TouchableHighlight
            onPress={() => {
              fecharLightbox();
            }}
          >
            <FontAwesome5
              name={"times"}
              color="#FFF"
              style={{ fontSize: 24 }}
            />
          </TouchableHighlight>
        )}
      </View>

      <View style={styles.container}>
        {loading.status ? (
          <Loading />
        ) : error.status ? (
          <Erro />
        ) : (
          <View style={{ height: "100%" }}>
            {showHeader && <Header />}

            <View style={styles.body}>
              {pagina == "ingressos" && <EscolhaIngressos />}
              {pagina == "pagamento" && <Pagamento />}
              {pagina == "processamento" && <Processamento />}
              {pagina == "reconhecimento" && <Reconhecimento />}
              {pagina == "confirmacao" && <Confirmacao />}
              {pagina == "selecionarTitular" && <SelecionarTitular />}
              {pagina == "login" && <Login />}
            </View>

            {!["confirmacao"].includes(pagina) && (
              <Footer style={styles.footer} />
            )}
          </View>
        )}
      </View>

      <View style={styles.copyright}>
        <Text style={{ color: "#FFF", fontSize: 16, fontWeight: 400 }}>
          Desenvolvido por
        </Text>
        <TouchableHighlight
          onPress={() => Linking.openURL("https://ipass.com.br")}
        >
          <Image
            style={{ width: 87, height: 42, marginLeft: 10 }}
            source={{
              uri: "https://res.cloudinary.com/ipass/image/upload/v1639158715/logoipass_ay4gm2.png",
            }}
          />
        </TouchableHighlight>
      </View>
    </SafeAreaView>
  );
};
export default Lightbox;

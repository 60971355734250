import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { View, Text, Button } from "react-native";
import { Form } from "@unform/mobile";
import Input from "../../components/Form/input";
import InputMask from "../../components/Form/inputMask";
import PickerSelect from "../../components/Form/select";
import CardValidator from "card-validator";
import { isMobile } from "react-device-detect";
import { formatCPF, formatReal } from "../../helpers/Format";
import Icon from "react-native-vector-icons/FontAwesome5";

import IconeBandeira from "../IconeBandeira";

import * as Yup from "yup";

import styles from "./styles";
import CompraContext from "../../contexts/compra";
import Colors from "../../values/colors";

import CartaoCredito, {
  getTokenCartao,
  getMetodoPagamento,
  getParcelas,
  efetuarPagamento,
} from "../../services/pagamentos/cartao-credito";

import { ValidCPF } from "../../helpers/Validate";

import LightboxContext from "../../contexts/lightbox";
import PagamentoContext from "../../contexts/pagamento";
import AuthContext from "../../contexts/auth";

import CheckBox from "../Form/checkbox";

import { Logger } from "../../helpers/Logger";
import EventoContext from "../../contexts/evento";
import SelectAutocomplete from "../Form/SelectAutocomplete";
import CitySelect from "../CitySelect";

const PagamentoCartaoCredito = ({ confirmarValoresDoacao }) => {
  const formRef = useRef(null);
  const [bandeiraCartao, setBandeiraCartao] = useState(null);
  const [seisDigitos, setSeisDigitos] = useState(null);

  const [iniciado, setIniciado] = useState(false);
  const [formError, setFormError] = useState(null);

  //
  const [formCartaoCorreto, setFormCartaoCorreto] = useState(false);
  const [dadosCartao, setDadosCartao] = useState(null);

  const [erroValorMinimo, setErroValorMinimo] = useState(false);

  // Informações transação / gateway
  const [metodoPagamento, setMetodoPagamento] = useState(null);
  const [parcelas, setParcelas] = useState([]);
  const [parcelaSelecionada, setParcelaSelecionada] = useState(1);

  const { tokenCheckout, setPagina, typeLightbox } =
    useContext(LightboxContext);
  const {
    setDoSubmit,
    submitResponse,
    setSubmitResponse,
    valorTotal,
    carrinho,
    efetuarCompra /*, comissario*/,
    valoresDoacao,
    reserva,
    formasPagamento,
    modoCompra,
  } = useContext(CompraContext);
  const { setErroPagamento, cartaoCreditoInserido, setCartaoCreditoInserido } =
    useContext(PagamentoContext);
  const { evento } = useContext(EventoContext);
  const { pessoa } = useContext(AuthContext);

  const movimento = {
    taxa_parcelamento: 0.05,
  };

  const [loadingForm, setLoadingForm] = useState(false);

  // Form
  const [pagadorNome, setPagadorNome] = useState("");
  const [pagadorEmail, setPagadorEmail] = useState("");
  const [pagadorCPF, setPagadorCPF] = useState("");
  const [pagadorTelefone, setPagadorTelefone] = useState("");
  const [pagadorCEP, setPagadorCEP] = useState("");
  const [pagadorLogradouro, setPagadorLogradouro] = useState("");
  const [pagadorNumero, setPagadorNumero] = useState("");
  const [pagadorBairro, setPagadorBairro] = useState("");
  const [pagadorComplemento, setPagadorComplemento] = useState("");
  const [pagadorReferencia, setPagadorReferencia] = useState("");
  const [pagadorCidade, setPagadorCidade] = useState("");

  const [titular, setNomeTitular] = useState("");
  const [numero, setNumero] = useState("");
  const [validade, setValidade] = useState("");
  const [CVV, setCVV] = useState("");

  const [solicitarCPF] = useState(true);
  const [cartaoTerceiro, setCartaoTerceiro] = useState(false);
  const [CPF, setCPF] = useState("");

  const setTitular = (text) => {
    setNomeTitular(text.toUpperCase());
  };

  useEffect(() => {
    setIniciado(true);
  }, []);

  useEffect(() => {
    atualizarParcelas(false);
  }, [valorTotal]);

  useEffect(() => {
    if (!pessoa && modoCompra != "link") setCartaoTerceiro(true);
  }, [pessoa]);

  useEffect(() => {
    //Logger.log("{ cartaoCreditoInserido }", cartaoCreditoInserido)
    if (cartaoCreditoInserido) {
      setTitular(cartaoCreditoInserido.titular);
      setNumero(cartaoCreditoInserido.numero);
      setValidade(cartaoCreditoInserido.validade);
      setCVV(cartaoCreditoInserido.cvv);
      setParcelaSelecionada(cartaoCreditoInserido.parcelas);
      if (
        cartaoCreditoInserido.cpf_titular &&
        cartaoCreditoInserido.cpf != cartaoCreditoInserido.cpf_titular
      ) {
        setCartaoTerceiro(true);
        setCPF(cartaoCreditoInserido.cpf_titular);
      } else setCartaoTerceiro(false);

      atualizarParcelas(
        cartaoCreditoInserido.numero.split(" ").join("").substring(0, 6)
      );
    }
  }, []);

  useEffect(() => {
    //console.log("useEffect [submitResponse]", submitResponse, iniciado)
    if (submitResponse != null) {
      if (iniciado) {
        //Logger.log("RETORNO DO SUBMIT", submitResponse)

        if (submitResponse == false) {
          setFormError("Preencha todos os dados corretamente");
          setLoadingForm(false);
        } else {
          setFormError(null);
          if (formCartaoCorreto == true) {
            //Logger.log("DADOS DE CARTÃO QUE SERÃO ENVIADOS", dadosCartao)
            let dados = { ...dadosCartao };

            setCartaoCreditoInserido(dados);
            //dados.cpf = formatCPF(submitResponse.doador.cpf)
            dados.cpf = submitResponse.doador.cpf;
            try {
              finalizarCompra(dadosCartao);
            } catch (error) {
              console.error(error);
              setFormError("Ocorreu um erro no pagamento");
            }
          }
        }
      }
    }
  }, [submitResponse]);

  const finalizarCompra = async (dadosCartao) => {
    try {
      console.log("finalizarCompra", dadosCartao, pessoa, metodoPagamento);

      // console.log("formasPagamento", formasPagamento)
      let formaPagamento = formasPagamento?.find(
        (fp) => fp.id == "cartao-credito"
      );
      // console.log("formaPagamento", formaPagamento)
      let gateway = formaPagamento?.gateway?.nome || "mercado-pago";

      let cpfTitular =
        modoCompra == "link"
          ? pagadorCPF.split(".").join("").split("-").join("")
          : cartaoTerceiro
          ? dadosCartao.cpf_titular.split(".").join("").split("-").join("")
          : pessoa.cpf;
      let { token_cartao, tokens_cartao } = await getTokenCartao(
        gateway,
        "CPF",
        cpfTitular,
        dadosCartao.titular,
        dadosCartao.numero,
        dadosCartao.validade,
        dadosCartao.cvv
      );

      // console.log("token_cartao", token_cartao)
      // console.log("metodo pagamento", metodoPagamento.id)

      if (dadosCartao?.pagador) {
        dadosCartao.pagador.cpf = dadosCartao?.pagador.cpf
          ?.split(".")
          .join("")
          .split("-")
          .join("");
        dadosCartao.pagador.telefone = dadosCartao?.pagador.telefone
          ?.split("(")
          .join("")
          .split(")")
          .join("")
          .split(" ")
          .join("")
          .split("-")
          .join("");
      }

      let dados = {
        meio_pagamento: "cartao-credito",
        token_cartao,
        tokens_cartao,
        parcelas: dadosCartao.parcelas,
        metodo_pagamento: metodoPagamento.id,
        cpf_titular: cpfTitular,
        doacoes: dadosCartao.doacoes,
        pagador: dadosCartao?.pagador,
      };
      console.log(dados);

      // throw { message: "teste - apagar" }

      //if (comissario) dados['comissario_id'] = comissario

      /*let ordemDoacao = await gerarDoacao(tokenCheckout, dados)
            //Logger.log("ORDEM DE DOAÇÃO COM CARTÃO DE CRÉDITO", ordemDoacao)

            setOrdemDoacao(ordemDoacao)*/

      console.log("FINALIZAR COMPRA", dados);

      efetuarCompra(dados);

      // setPagina("processamento")
    } catch (error) {
      setLoadingForm(false);
      setErroPagamento("Ocorreu um erro");
      Logger.log("ERRO COMPRA", error);
    }
  };

  const handleSubmit = async (data) => {
    Logger.log(`handleSubmit`, data);

    setLoadingForm(() => true);

    data.doacoes = [];
    Object.keys(valoresDoacao).forEach((key) => {
      data.doacoes = [...data.doacoes, ...valoresDoacao[key]];
    });

    //join the array of strings in one string
    data.numero = data.numero.join("");

    //console.log("data", data)

    setDadosCartao(data);

    try {
      console.log("handleSubmit dentro do try", data);
      setFormCartaoCorreto(false);

      let shape = {
        titular: Yup.string().required("Campo obrigatório"),
        numero: Yup.string()
          .test(
            "test-number",
            "Número inválido",
            (value) => CardValidator.number(value).isValid
          )
          .required("Campo obrigatório"),
        validade: Yup.string()
          .test("len", "Validade incorreta", (value) => value.length == 5)
          .required("Campo obrigatório"),
        cvv: Yup.string()
          .test(
            "len",
            "CVV inválido",
            (value) => value.length == 3 || value.length == 4
          )
          .required("Campo obrigatório"),
      };

      if (modoCompra == "link") {
        shape["pagador"] = Yup.object()
          .shape({
            nome: Yup.string().required("Campo obrigatório"),
            email: Yup.string()
              .email("E-mail inválido")
              .required("Campo obrigatório"),
            cpf: Yup.string().required("Campo obrigatório"),
            telefone: Yup.string().required("Campo obrigatório"),
            endereco: Yup.object().shape({
              cep: Yup.string().required("Campo obrigatório"),
              logradouro: Yup.string().required("Campo obrigatório"),
              numero: Yup.string().required("Campo obrigatório"),
              bairro: Yup.string().required("Campo obrigatório"),
              cidade: Yup.string().required("Campo obrigatório"),
            }),
          })
          .required();
      }

      if (cartaoTerceiro && modoCompra != "link") {
        shape["cpf_titular"] = Yup.string()
          .test("test-cpf", "CPF inválido", (value) => {
            return ValidCPF(value);
          })
          .required("Campo obrigatório se não for o titular");
      }

      const schema = Yup.object().shape(shape);

      // Form doação
      setSubmitResponse(null);
      setDoSubmit(true);

      await schema.validate(data, {
        abortEarly: false,
      });

      await confirmarValoresDoacao();

      formRef.current.setErrors({});

      setFormCartaoCorreto(true);

      finalizarCompra(data);
    } catch (error) {
      console.error(error);
      setDadosCartao(null);
      setLoadingForm(() => false);

      //Logger.log("ERRO", error)
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);

        await confirmarValoresDoacao();
      }
    }
  };

  const atualizarParcelas = async (seisDigitos) => {
    //Logger.log("{ getParcelas }", seisDigitos, valorTotal)
    if (seisDigitos == false) {
      // Ou seja, veio do userEffect
      seisDigitos = "";
      let numero = formRef.current.getFieldValue("numero");
      if (numero && numero.length >= 6)
        seisDigitos = numero.split(" ").join("").substring(0, 6);
    }

    if (
      (!valorTotal["cartao-credito"] && valorTotal["cartao-credito"] != 0) ||
      !seisDigitos ||
      seisDigitos.length < 6
    ) {
      setMetodoPagamento(null);
      setParcelas([]);
      if (
        seisDigitos.length >= 6 &&
        valorTotal["cartao-credito"] &&
        valorTotal["cartao-credito"] != 0
      )
        setErroValorMinimo(true);
      return;
    }

    setErroValorMinimo(false);

    let metodo_pagamento = await getMetodoPagamento(seisDigitos);
    setMetodoPagamento(metodo_pagamento);

    // console.log({ evento, reserva })

    let parc = await getParcelas(
      metodo_pagamento.id,
      valorTotal["cartao-credito"],
      movimento.hasOwnProperty("taxa_parcelamento")
        ? movimento.taxa_parcelamento
        : null,
      evento?._id || reserva?.evento
    );

    let parcs = [];
    parc.forEach((item) => {
      parcs.push({
        parcela: item.parcelas,
        label: item.descricao,
        value: item.parcelas,
      });
    });

    setParcelas(parcs);
    //Logger.log("PARCELAS", parcelas)
  };

  const verificarBandeira = (numero) => {
    console.log("{ verificarBandeira }", numero);
    let validacao = CardValidator.number(numero);
    if (validacao && validacao.card && validacao.card.type)
      setBandeiraCartao(validacao.card.type);
  };

  const verificarPreenchimentoNumeroCartao = (value) => {
    let numero = value;

    console.log("Numero: ", numero);

    numero = numero.split(" ").join("");

    console.log("Numero formatado: ", numero);

    if (numero.length >= 6) {
      let seis = numero.substring(0, 6);

      if (seis != seisDigitos) {
        // Verificação feita para que não haja requisições ao gateway sem necessidade para pegar parcelamentos
        setSeisDigitos(seis);
        atualizarParcelas(seis);
        verificarBandeira(numero);
      }
    } else {
      if (parcelas) setParcelas([]);
      if (seisDigitos) setSeisDigitos(null);
    }
  };

  const Divider = ({ text, marginBottom = 10, marginTop = 5 }) => (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom,
        marginTop,
      }}
    >
      <Text
        style={{
          color: "#68717A",
          paddingRight: 5,
          fontSize: 14,
          fontWeight: "400",
        }}
      >
        {text}
      </Text>
      <View style={{ flex: 1, height: 1, backgroundColor: "#CED4D9" }}></View>
    </View>
  );

  return (
    <View>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="titular"
          label="Nome do Titular"
          placeholder="Nome igual ao escrito no cartão"
          autoCapitalize="characters"
          value={titular}
          onChange={(e) => {
            setTitular(e.target.value);
          }}
        />
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <InputMask
            styleView={{ flex: 1 }}
            type="credit-card"
            name="numero"
            keyboardType="numeric"
            label="Número do Cartão"
            value={numero}
            onChange={(e) => {
              verificarPreenchimentoNumeroCartao(e.target.value);
              setNumero(e.target.value);
            }}
            placeholder="Número escrito no cartão"
          />
          {bandeiraCartao == null ? (
            <Icon
              name={"credit-card"}
              color={Colors.primary}
              size={50}
              style={{ alignItems: "flex-end", paddingTop: 5, paddingLeft: 10 }}
            />
          ) : (
            <IconeBandeira bandeira={bandeiraCartao} />
          )}
        </View>

        <View style={{ flex: 1, flexDirection: isMobile ? "column" : "row" }}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              marginBottom: isMobile ? 20 : 0,
            }}
          >
            <InputMask
              type="custom"
              options={{ mask: "99/99" }}
              styleView={{ flex: 1, marginRight: isMobile ? 5 : 10 }}
              name="validade"
              label="Validade"
              placeholder="MM/AA"
              value={validade}
              onChange={(e) => {
                setValidade(e.target.value);
              }}
            />
            <InputMask
              type="custom"
              options={{ mask: "9999" }}
              styleView={{
                flex: 1,
                marginRight: isMobile ? 0 : 10,
                marginLeft: isMobile ? 5 : 0,
              }}
              name="cvv"
              label="CVV"
              placeholder="CVV"
              value={CVV}
              onChange={(e) => {
                setCVV(e.target.value);
              }}
            />
          </View>
          <PickerSelect
            name="parcelas"
            items={parcelas}
            label="Parcelamento"
            value={parcelaSelecionada}
            placeholder={
              parcelas.length == 0
                ? { label: "Informe seu cartão", value: "" }
                : {}
            }
            disabled={parcelas.length == 0}
            onValueChange={(value) => {
              setParcelaSelecionada(parseInt(value));
            }}
          />
          {/*<Text style={{ fontSize: 12, textAlign: "right" }}>{movimento.taxa_parcelamento * 100}% a.m.</Text>*/}
        </View>

        {solicitarCPF && modoCompra != "link" && (
          <View>
            {pessoa && (
              <CheckBox
                value={cartaoTerceiro}
                onValueChange={setCartaoTerceiro}
                text="O cartão utilizado é de outra pessoa"
              />
            )}

            {cartaoTerceiro && (
              <InputMask
                type="cpf"
                name="cpf_titular"
                label="CPF"
                placeholder="Informe o CPF do titular do cartão"
                value={CPF}
                onChange={(e) => {
                  setCPF(e.target.value);
                }}
                styleView={{ marginTop: pessoa ? 10 : 0 }}
              />
            )}
          </View>
        )}
        {modoCompra == "link" && (
          <>
            <Divider text="Dados do pagador" marginTop={10} />

            <Input
              name="pagador.nome"
              label="Nome completo"
              placeholder="Informe seu nome completo"
              value={pagadorNome}
              onChange={(e) => {
                setPagadorNome(e.target.value);
              }}
            />
            <InputMask
              name="pagador.cpf"
              type="cpf"
              keyboardType="numeric"
              label="CPF"
              placeholder="Informe seu CPF"
              value={pagadorCPF}
              onChange={(e) => {
                setPagadorCPF(e.target.value);
              }}
            />
            <Input
              name="pagador.email"
              label="E-mail"
              placeholder="Informe seu e-mail"
              value={pagadorEmail}
              onChange={(e) => {
                setPagadorEmail(e.target.value);
              }}
            />
            <InputMask
              name="pagador.telefone"
              type="cel-phone"
              keyboardType="numeric"
              label="Celular"
              placeholder="Informe seu celular" /*value={titular} onChange={(e) => { setTitular(e.target.value) }}*/
            />

            <Divider text="Endereço do pagador" />

            <InputMask
              name="pagador.endereco.cep"
              type="zip-code"
              keyboardType="numeric"
              label="CEP"
              placeholder="CEP" /*value={titular} onChange={(e) => { setTitular(e.target.value) }}*/
            />
            <View
              style={isMobile ? {} : { flexDirection: "row", width: "100%" }}
            >
              <Input
                name="pagador.endereco.logradouro"
                label="Logradouro"
                placeholder="Rua, av, etc."
                /*value={titular} onChange={(e) => { setTitular(e.target.value) }}*/ styleView={
                  isMobile ? {} : { flex: 1, marginRight: 10 }
                }
              />
              <Input
                name="pagador.endereco.numero"
                label="Número"
                placeholder="Número" /*value={titular} onChange={(e) => { setTitular(e.target.value) }}*/
              />
            </View>
            <View
              style={isMobile ? {} : { flexDirection: "row", width: "100%" }}
            >
              <Input
                name="pagador.endereco.bairro"
                label="Bairro"
                placeholder="Bairro"
                /*value={titular} onChange={(e) => { setTitular(e.target.value) }}*/ styleView={
                  isMobile ? {} : { flex: 1, marginRight: 10 }
                }
              />
              <Input
                name="pagador.endereco.complemento"
                label="Complemento"
                placeholder="Complemento"
                /*value={titular} onChange={(e) => { setTitular(e.target.value) }}*/ styleView={
                  isMobile ? {} : { flex: 1, marginRight: 10 }
                }
              />
              <Input
                name="pagador.endereco.referencia"
                label="Referência"
                placeholder="Referência"
                /*value={titular} onChange={(e) => { setTitular(e.target.value) }}*/ styleView={
                  isMobile ? {} : { flex: 1 }
                }
              />
            </View>
            <CitySelect
              name="pagador.endereco.cidade"
              label="Cidade"
              placeholder="Escolha a cidade"
            />
          </>
        )}

        {formError && (
          <View
            style={{
              padding: 5,
              borderColor: Colors.error,
              borderWidth: 1,
              borderRadius: 3,
              marginTop: 10,
            }}
          >
            <Text style={{ color: "red", textAlign: "center" }}>
              {formError}
            </Text>
          </View>
        )}

        <View style={{ marginTop: 20 }}>
          <Button
            title={loadingForm ? "Carregando..." : "Efetuar pagamento"}
            color={Colors.success}
            onPress={() => {
              formRef.current.submitForm();
            }}
            disabled={loadingForm}
          />
        </View>
      </Form>

      <CartaoCredito />
    </View>
  );
};

export default PagamentoCartaoCredito;

export const formatData = (dt, extenso = false) => {
  let data = new Date(dt);

  let dia = data.getDate();
  let mes = data.getMonth();
  let ano = data.getFullYear();

  if (dia < 10) dia = `0${dia}`;

  let meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  if (!extenso) return `${dia}/${mes}/${ano}`;
  return `${dia} de ${meses[mes]} de ${ano}`;
};

export const formatReal = (valor) => {
  if (valor == 0) return "R$ 0,00";
  else if (!valor) return;
  valor = valor + 0.0;
  return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};

export const formatCPF = (cpf) => {
  if (cpf.length != 11) return "";
  return `${cpf[0]}${cpf[1]}${cpf[2]}.${cpf[3]}${cpf[4]}${cpf[5]}.${cpf[6]}${cpf[7]}${cpf[8]}-${cpf[9]}${cpf[10]}`;
};

export const formatCelular = (numero) => {
  let ddd = numero.substring(0, 2);
  let nove = numero.length == 11 ? numero.substring(2, 3) : "";
  let primeira =
    numero.length == 11 ? numero.substring(3, 7) : numero.substring(2, 6);
  let segunda =
    numero.length == 11 ? numero.substring(7, 11) : numero.substring(6, 10);

  return `(${ddd}) ${nove}${primeira}-${segunda}`;
};

export const substituirVariaveisText = (text, obj) => {
  return text
    .split("${")
    .map((part) => {
      let findVariable = part.split("}");
      if (findVariable.length > 1) {
        findVariable[0] = obj?.[findVariable[0]] || findVariable[0];
      }
      return findVariable.join("");
    })
    .join("");
};

import React from 'react'
import { View, Image } from 'react-native'

import visa from '../../../assets/bandeiras/visa.png'
import mastercard from '../../../assets/bandeiras/mastercard.png'
import maestro from '../../../assets/bandeiras/maestro.png'
import hipercard from '../../../assets/bandeiras/hipercard.png'
import elo from '../../../assets/bandeiras/elo.png'
import diners from '../../../assets/bandeiras/diners-club.png'
import bb from '../../../assets/bandeiras/banco-do-brasil.png'
import american from '../../../assets/bandeiras/american-express.png'
import alelo from '../../../assets/bandeiras/alelo.png'

const IconeBandeira = ({ bandeira, styleView, styleImage, ...rest }) => {
    let bandeiras = [
        { nome: "visa", icone: visa },
        { nome: "mastercard", icone: mastercard },
        { nome: "maestro", icone: maestro },
        { nome: "hipercard", icone: hipercard },
        { nome: "elo", icone: elo },
        { nome: "diners-club", icone: diners },
        { nome: "banco-do-brasil", icone: bb },
        { nome: "american-express", icone: american },
        { nome: "alelo", icone: alelo },
    ]

    let icone = bandeiras.filter(band => band.nome == bandeira)

    if (!icone || icone.length == 0) return null

    icone = icone[0]

    return (
        <View style={{ width: 120, alignItems: 'flex-end', paddingTop: 20 }}>
            <Image
                style={[{
                    width: 114,
                    height: 48
                }, styleImage]}
                source={{
                    uri: icone.icone
                }}
            />
        </View>
    )
}

export default IconeBandeira
import React, { useEffect, useState, useContext } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View, Text, Image, Button } from 'react-native'
import QRCode from 'react-native-qrcode-svg';
import { isMobile } from "react-device-detect";

import Loading from '../../pages/Loading'

import styles from './styles'
import InputCopy from '../../components/Form/inputCopy'

import QRCodeBlur from '../../../assets/images/qrcode-blur.png'

import Colors from '../../values/colors'

// Contexts
import LightboxContext from '../../contexts/lightbox';
import PagamentoContext from '../../contexts/pagamento';
import EventoContext from '../../contexts/evento';
import CompraContext from '../../contexts/compra';
import WebSocketContext from '../../contexts/webSocket';

// Services
//import { gerarDoacao } from '../../services/doacao'

import { Logger } from '../../helpers/Logger'
import API from '../../services/API';
import AuthContext from '../../contexts/auth';

const PagamentoPIX = ({ confirmarValoresDoacao }) => {
    const { evento } = useContext(EventoContext)
    const { pessoa } = useContext(AuthContext)

    const [iniciado, setIniciado] = useState(false)
    const [loadingPIX, setLoadingPIX] = useState(false)
    const [gerado, setGerado] = useState(false)
    const { pix, setPix, setErroPagamento } = useContext(PagamentoContext)
    const { tokenCheckout, setPagina } = useContext(LightboxContext)
    const { setCompra, formAlterado, setFormAlterado/*, comissario*/, valoresDoacao, efetuarCompra, setTokenReserva, modoPagamento } = useContext(CompraContext)
    const [formError, setFormError] = useState(false)
    const [errorPIX, setErrorPIX] = useState(false)
    const { onSocket } = useContext(WebSocketContext)
    //const { setOrdemDoacao } = useContext(DoacaoContext)

    const gerarPIXclick = async () => {
        try {
            await confirmarValoresDoacao()

            setGerado(null)
            setFormError(false)
            //setSubmitResponse(null)
            //setDoSubmit(true)

            let doacoes = []
            for (let key of Object.keys(valoresDoacao)) {
                doacoes = [...doacoes, ...valoresDoacao[key]]
            }

            let dados = {
                doacoes,
                meio_pagamento: "pix"
            }

            solicitarPIX(dados)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (formAlterado == true) {
            setGerado(false)
            setFormAlterado(false)
        }
    }, [formAlterado])

    useEffect(() => {
        setIniciado(true)
    }, [])

    const solicitarPIX = async (dados) => {
        try {
            console.log("solicitarPIX", dados)

            //if (comissario) dados['comissario_id'] = comissario

            let compra = await efetuarCompra(dados)
            console.log("{ solicitarPIX COMPRA }", compra)
            setPix({
                qrcode: compra?.fatura?.pix?.brcode
            })
            setGerado(true)

            setTimeout(() => consultarCompra(compra._id), 5000)

            /*onSocket(`pix-gerado:${compra.ordem_pagamento._id}`, data => {
                //Logger.log("PIX GERADO", data)
                setPix({
                    qrcode: data.brcode
                })
                setGerado(true)
            })

            onSocket(`compra-atualizada:${compra._id}`, data => { // Pagamento feito e compra atualizada
                Logger.log("COMPRA ATUALIZADA", data)

                setCompra(() => data)

                if (data.status == 1) {
                    setPagina("confirmacao")
                    if (!modoPagamento)
                        setTokenReserva(null)
                    AsyncStorage.removeItem(`iPass-LB-token-reserva-${evento.etapa._id}`)
                }
            })*/
        } catch (error) {
            setGerado(true)
            setErrorPIX(true)
        }
    }

    const consultarCompra = async (compra_id) => {
        try {
            let response = await API.get(`/compras/${compra_id}`)
            let compra = response.data

            setCompra(() => compra)

            if (compra.status == 1) {
                setPagina(pessoa?.faceURL ? "reconhecimento" : "confirmacao")
            } else
                setTimeout(() => consultarCompra(compra_id), 3000)
        } catch (error) {
            throw error
        }
    }

    return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
            {
                loadingPIX ?
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Loading logo={false} text="Gerando QRCode para pagamento por PIX" styleText={{
                            fontSize: 20,
                            color: "#F00"
                        }} />
                    </View>
                    :
                    <View style={styles.container}>
                        <View style={styles.pixContainer}>
                            <View style={{ alignItems: 'center' }}>
                                <View style={{
                                    width: 190,
                                    height: 190,
                                    borderColor: "#CED4DA",
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {errorPIX == true ?
                                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <Text>Tente novamente</Text>
                                        </View>
                                        : gerado == true && pix ?
                                            <QRCode
                                                value={pix.qrcode}
                                                size={170}
                                            /> :
                                            gerado == null ?
                                                <Loading logo={false} />
                                                : <Image style={{
                                                    width: 190,
                                                    height: 190
                                                }} source={{
                                                    uri: QRCodeBlur
                                                }} />
                                    }
                                </View>

                                {gerado && pix ?
                                    <InputCopy textToCopy={pix.qrcode} styleForm={{ flex: 1 }} />
                                    :
                                    <View style={{ marginTop: 10, borderRadius: 10 }}>
                                        <Button title="Gerar PIX" color={Colors.primary} onPress={() => { gerarPIXclick() }} disabled={gerado != false} />
                                    </View>
                                }
                            </View>
                            <View style={{ textAlign: 'left', paddingLeft: 20, flex: 1 }}>
                                <Text style={[styles.instrucao, { fontWeight: "bold", fontSize: 22 }]}>Passo a passo PIX</Text>
                                <Text style={styles.instrucao}><Text style={{ fontWeight: "bold" }}>1.</Text> Clique no botão <Text style={{ fontWeight: "bold" }}>GERAR PIX</Text> {isMobile ? "abaixo" : "ao lado"}</Text>
                                <Text style={styles.instrucao}><Text style={{ fontWeight: "bold" }}>2.</Text> Abra o app do seu banco ou instituição financeira e entre em <Text style={{ fontWeight: "bold" }}>PIX</Text></Text>
                                <Text style={styles.instrucao}><Text style={{ fontWeight: "bold" }}>3.</Text> Escolha a opção <Text style={{ fontWeight: "bold" }}>Pagar com QR Code</Text> ou <Text style={{ fontWeight: "bold" }}>PIX Copia e Cola</Text></Text>
                                <Text style={styles.instrucao}><Text style={{ fontWeight: "bold" }}>4. Leia o QR Code</Text> {isMobile ? "abaixo" : "ao lado"} ou <Text style={{ fontWeight: "bold" }}>Copie e Cole o código</Text> apresentado</Text>
                                <Text style={styles.instrucao}><Text style={{ fontWeight: "bold" }}>5.</Text> Aguarde a confirmação nesta página por alguns segundos <Text style={{ fontStyle: "italic" }}>(aguarde até 2 minutos)</Text></Text>
                            </View>
                        </View>

                        {formError &&
                            <View style={{ padding: 5, borderColor: Colors.error, borderWidth: 1, borderRadius: 3, marginTop: 10 }}>
                                <Text style={{ color: 'red', textAlign: 'center' }}>{formError}</Text>
                            </View>
                        }
                    </View>
            }
        </View>
    )
}

export default PagamentoPIX
import { StyleSheet } from 'react-native'
import { isMobile } from "react-device-detect";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    area_forma_pagamento: {
        borderBottomColor: 'rgba(102, 102, 102, 0.35)',
        borderBottomWidth: StyleSheet.hairlineWidth,
        paddingVertical: 10
    },
    area_forma_pagamento2: {
        borderBottomColor: 'rgba(102, 102, 102, 0.35)',
        borderBottomWidth: StyleSheet.hairlineWidth,
        paddingVertical: 10
    },
    botao_forma_pagamento: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        borderWidth: 1,
        borderColor: '#01AC8A',
        borderRadius: 5,
        marginHorizontal: 10,
        marginTop: 10
    },
    botao_forma_pagamento_selecionada: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        borderWidth: 1,
        borderColor: '#01AC8A',
        backgroundColor: '#01AC8A',
        borderRadius: 5,
        marginHorizontal: 10,
        marginTop: 10
    },
    divisoria1: Object.assign({},
        {
            borderRightColor: 'rgba(102, 102, 102, 0.35)',
            borderRightWidth: 1,
            padding: 24,
        }, isMobile ? {} : {
            flex: 1,
            height: '100%',
        }),
    divisoria2: {
        flex: 1
    }
});

export default styles
import React from 'react'

//import { getPaymentMethod as MPgetPaymentMethod } from './gateways/MercadoPago'
import MercadoPago from './gateways/MercadoPago'
import PagarMe from './gateways/PagarMe'

import { Logger } from '../../helpers/Logger'
import API from '../API'

const gatewayUtilizado = "mercado-pago"

const init = () => {
    if (gatewayUtilizado == "mercado-pago") {
        const { getScript } = MercadoPago()
        return getScript()
    }
}
export default init

export const getMetodoPagamento = async (numero) => {
    const { getPaymentMethod } = MercadoPago()
    let metodo = await getPaymentMethod(numero)
    Logger.log("METODO DE PAGAMENTO", metodo)
    return metodo
}

export const getParcelas = async (metodo_pagamento_id, valor, taxaDefinida = null, evento_id) => {
    console.log("{ getParcelas }", metodo_pagamento_id, valor, taxaDefinida)

    let result = await API.get(`/pagamentos/parcelamentos/${evento_id}/${metodo_pagamento_id}/${valor}`)
    if (!result?.data) return []

    let { data: parcelas } = result

    // console.log("parcelas", parcelas)

    return parcelas
}

// Recebe dados do cartão, método de pagamento e quantidade parcelas para gerar token do cartão e enviar ao gateway
export const getTokenCartao = async (gateway, tipo_documento, documento, titular, numero, validade, cvv) => {
    try {
        Logger.log("{ getTokenCartao }", gateway, tipo_documento, documento, titular, numero, validade, cvv)

        if (!["mercado-pago", "pagar.me"].includes(gateway))
            throw { status: 404, message: "Gateway não identificado" }

        let token_cartao;
        let tokens_cartao = {};

        // Mercado Pago INÍCIO
        try {
            const { getCardToken: getCardToken_MercadoPago } = MercadoPago()

            let response_MercadoPago = await getCardToken_MercadoPago(tipo_documento.toUpperCase(), documento, titular, numero, validade, cvv)

            tokens_cartao["mercado-pago"] = response_MercadoPago.id
        } catch (error) {
            if (gateway == "mercado-pago")
                throw error
        }
        // Mercado Pago FIM

        // Pagar.me INÍCIO
        try {
            let { getCardToken: getCardToken_PagarMe } = PagarMe()

            let response_PagarMe = await getCardToken_PagarMe(documento, titular, numero, validade, cvv)

            tokens_cartao["pagar.me"] = response_PagarMe.id
        } catch (error) {
            if (gateway == "pagar.me")
                throw error
        }
        // Pagar.me FIM

        token_cartao = tokens_cartao[gateway]

        return { token_cartao, tokens_cartao }
    } catch (error) {
        throw error
    }
}
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    text: {
        marginRight: 20,
        color: '#FFF',
        fontSize: 20
    }
})

export default styles
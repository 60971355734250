import React, { useState, useEffect, useContext, useRef } from "react";
import {
  ScrollView,
  View,
  Dimensions,
  Text,
  Image,
  Button,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome5";
import { isBrowser, isMobile } from "react-device-detect";

import { Form } from "@unform/mobile";
import Input from "../../components/Form/input";

import styles from "./styles";

import { formatReal } from "../../helpers/Format";

import Publicidade from "../../components/Publicidade";

import PagamentoCartaoCredito from "../../components/PagamentoCartaoCredito";
import PagamentoDebitoElo from "../../components/PagamentoDebitoElo";
import PagamentoPIX from "../../components/PagamentoPIX";

import API from "../../services/API";

import FeelingHappy from "../../../assets/images/feeling-happy.svg";

// Contexts
import EventoContext from "../../contexts/evento";
import CompraContext from "../../contexts/compra";
import AuthContext from "../../contexts/auth";
import LightboxContext from "../../contexts/lightbox";
import PagamentoContext from "../../contexts/pagamento";
import { Logger } from "../../helpers/Logger";

const Pagamento = () => {
  const formRef = useRef();

  const { tokenLightbox, setLoading, setError } = useContext(LightboxContext);
  const { evento } = useContext(EventoContext);
  const {
    carrinho,
    valorTotal,
    reserva,
    valoresDoacao,
    setValoresDoacao,
    modoPagamento,
  } = useContext(CompraContext);
  const { erroPagamento } = useContext(PagamentoContext);

  const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] =
    useState("pix");

  let formasPagamento = [
    { id: "pix", nome: "PIX", descricao: "Instantâneo", icone: "qrcode" },
    {
      id: "cartao-credito",
      nome: "Cartão de Crédito",
      descricao: "Instantâneo e em até 12x",
      icone: "credit-card",
    },
  ];

  useEffect(() => {
    atualizarValoresDoacao();
  }, [carrinho]);

  useEffect(() => {
    if (modoPagamento) setFormaPagamentoSelecionada("cartao-credito");
  }, [modoPagamento]);

  useEffect(() => {
    console.log("valoresDoacao", valoresDoacao);
  }, [valoresDoacao]);

  const atualizarValoresDoacao = () => {
    console.log("atualizarValoresDoacao", carrinho);
    let json = {};
    carrinho
      .filter((c) => c.lote.modalidade?.solidario == true)
      .forEach((ingresso) => {
        json[`lote_${ingresso.lote._id}`] = [...Array(ingresso.quantidade)].map(
          () => {
            return {
              lote_id: ingresso.lote._id,
              valor_minimo_doacao:
                ingresso?.lote?.modalidade?.valor_minimo_doacao,
              valor_doacao: 0,
              erro: null,
            };
          }
        );
      });
    console.log(json);
    setValoresDoacao(json);

    // formRef?.current?.setFieldData("doacao_solidario[0]", 10)
    setValoresDoacao((valoresDoacao) => {
      let valores = { ...valoresDoacao };

      carrinho
        .filter((c) => c.lote.modalidade?.solidario == true)
        .forEach((ingresso) => {
          [...Array(ingresso.quantidade)].map((x, index2) => {
            console.log("index2", index2, ingresso.lote._id);
            valores[`lote_${ingresso.lote._id}`][index2] = {
              ...valores[`lote_${ingresso.lote._id}`][index2],
              valor_doacao: ingresso?.titulares?.[index2]?.valor_doacao || 0,
              erro: null,
            };
          });
        });

      return valores;
    });
  };

  const getIngresso = (lote_id) => {
    console.log("{ getIngresso }", lote_id, evento);
    let retorno = null;

    if (evento?.etapa?.setores) {
      for (let setor of evento.etapa.setores) {
        for (let ingresso of setor.lotes) {
          if (ingresso._id == lote_id) {
            retorno = {
              ...ingresso,
              setor_nome: setor.nome,
              etapa_nome: evento.etapa.nome,
            };
            break;
          }
        }
        if (retorno) break;
      }
    }

    return retorno;
  };

  const confirmarValoresDoacao = async () => {
    try {
      Logger.log("confirmarValoresDoacao", valoresDoacao);

      let erros = false;
      let valores = { ...valoresDoacao };
      Object.keys(valores).forEach((key) => {
        for (let doacao of valores[key]) {
          //console.log(doacao.valor_doacao, doacao.valor_minimo_doacao, doacao.valor < doacao.valor_minimo_doacao)
          if (
            doacao.valor_doacao == undefined ||
            doacao.valor_doacao < doacao.valor_minimo_doacao
          ) {
            doacao.erro = `Mínimo de ${formatReal(doacao.valor_minimo_doacao)}`;
            erros = true;
          }
        }
      });

      //console.log("erros", erros, valoresDoacao)

      if (erros) {
        setValoresDoacao(valores);
        throw "Erro";
      }

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getTotalDoacoesIngresso = (lote_id) => {
    //console.log("getTotalDoacoesIngresso", lote_id, valoresDoacao)

    let soma = 0;
    if (valoresDoacao[`lote_${lote_id}`]) {
      valoresDoacao[`lote_${lote_id}`].forEach((valor) => {
        soma += valor.valor_doacao;
      });
    }

    return soma;
  };

  return (
    <ScrollView style={{ flex: 1, display: "flex", height: "100%" }}>
      <View style={{ flexGrow: 1, height: "100%" }}>
        <View
          style={Object.assign(
            {},
            isMobile ? {} : { flex: 1, flexDirection: "row", height: "100%" }
          )}
        >
          <View style={styles.divisoria1}>
            {carrinho.filter(
              (i) => (i?.lote?.modalidade || i?.modalidade)?.solidario == true
            ).length > 0 && (
              <View style={{ marginTop: 20, marginBottom: 20 }}>
                <Text
                  style={{ fontSize: 20, fontWeight: 500, marginBottom: 10 }}
                >
                  Doações de Ingressos Solidários
                </Text>

                <Form ref={formRef}>
                  {carrinho
                    .filter((i) => i.lote.modalidade?.solidario == true)
                    .map((ingresso, index1) => {
                      console.log("ING KK", ingresso, index1);
                      return [...Array(ingresso.quantidade).keys()].map(
                        (index2) => {
                          let index = index1 * (index2 + 1) + (index2 + 1);
                          return (
                            <View
                              key={`${index1}-${index2}`}
                              style={{
                                marginTop: 5,
                                marginBottom: 5,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <View>
                                <Text
                                  style={{ fontSize: 16, marginBotton: 5 }}
                                >{`Ingresso Solidário ${index}`}</Text>
                                <Text>{`${ingresso.lote.setor.nome} | ${ingresso.lote.nome}`}</Text>
                              </View>
                              <View>
                                <Input
                                  type="money"
                                  name={`doacao_solidario[${index}]`}
                                  label="Valor da doação"
                                  placeholder="R$"
                                  defaultValue={
                                    ingresso?.titulares?.[index2]?.valor_doacao
                                  }
                                  errorMessage={
                                    valoresDoacao[`lote_${ingresso.lote._id}`]
                                      ? valoresDoacao[
                                          `lote_${ingresso.lote._id}`
                                        ][index2]?.erro
                                      : null
                                  }
                                  onChange={(val) =>
                                    setValoresDoacao((valoresDoacao) => {
                                      console.log(
                                        "valoresDoacao",
                                        valoresDoacao
                                      );
                                      console.log("INGGGG", ingresso);
                                      console.log("index2", index2);
                                      console.log(`lote_${ingresso.lote._id}`);
                                      let valores = { ...valoresDoacao };
                                      valores[`lote_${ingresso.lote._id}`][
                                        index2
                                      ] = {
                                        ...valores[`lote_${ingresso.lote._id}`][
                                          index2
                                        ],
                                        valor_doacao: val,
                                        erro: null,
                                      };
                                      return valores;
                                    })
                                  }
                                />
                              </View>
                            </View>
                          );
                        }
                      );
                    })}
                </Form>
              </View>
            )}

            <Text style={{ fontSize: 20, fontWeight: 500, marginBottom: 10 }}>
              Resumo da Compra
            </Text>

            {carrinho.map((ingresso) => {
              console.log("ING___", ingresso);
              return (
                <View
                  key={ingresso.lote._id}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 5,
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Icon
                      name={"circle"}
                      color="#01AC8A"
                      style={{ paddingTop: 3, paddingRight: 10 }}
                    />
                    <Text
                      style={{ fontSize: 17, color: "#68717A" }}
                    >{`${ingresso.quantidade}x | ${ingresso.lote.nome} | ${ingresso.lote.modalidade?.nome}`}</Text>
                  </View>
                  <View
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 17,
                        color: "#68717A",
                        fontWeight: 600,
                      }}
                    >
                      {formatReal(ingresso.lote.valor * ingresso.quantidade)}
                    </Text>
                    {!ingresso?.lote.absorver_taxas &&
                      ingresso?.lote.taxas[formaPagamentoSelecionada]
                        ?.valor_taxa > 0 && (
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#68717A",
                            fontWeight: 400,
                            marginTop: 2,
                          }}
                        >
                          + taxa de{" "}
                          {formatReal(
                            ingresso.lote.taxas[formaPagamentoSelecionada]
                              .valor_taxa * ingresso.quantidade
                          )}
                        </Text>
                      )}
                    {ingresso.lote.modalidade?.solidario == true && (
                      <Text
                        style={{
                          fontSize: 14,
                          color: "#00B5F7",
                          fontWeight: 400,
                          marginTop: 2,
                        }}
                      >
                        + doação de{" "}
                        {formatReal(getTotalDoacoesIngresso(ingresso.lote._id))}
                      </Text>
                    )}
                  </View>
                </View>
              );
            })}

            <View style={{ marginTop: 20, marginBottom: 20 }}>
              {reserva.desconto > 0 && (
                <View
                  style={{
                    marginBottom: 10,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: 16, fontWeight: 500 }}>
                    Desconto
                  </Text>
                  <Text
                    style={{ fontSize: 17, color: "#68717A", fontWeight: 600 }}
                  >
                    - {formatReal(reserva.desconto)}
                  </Text>
                </View>
              )}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 20, fontWeight: 500 }}>Total</Text>
                <Text style={{ fontSize: 20, fontWeight: 500 }}>
                  {formatReal(valorTotal[formaPagamentoSelecionada])}
                </Text>
              </View>
            </View>

            {/*<Publicidade />*/}
          </View>
          <View style={styles.divisoria2}>
            {valorTotal.pix > 0 && (
              <View style={styles.area_forma_pagamento2}>
                {!modoPagamento && (
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      alignSelf: "center",
                    }}
                  >
                    Escolha a forma de pagamento
                  </Text>
                )}

                <View
                  style={{
                    flex: 1,
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  {formasPagamento
                    .filter((fp) => !modoPagamento || fp.id == "cartao-credito")
                    .map((forma) => (
                      <View key={forma.id} style={{ flex: 1 }}>
                        <TouchableOpacity
                          style={
                            formaPagamentoSelecionada == forma.id
                              ? styles.botao_forma_pagamento_selecionada
                              : styles.botao_forma_pagamento
                          }
                          onPress={() => {
                            setFormaPagamentoSelecionada(forma.id);
                          }}
                        >
                          <View style={{ flex: 1, alignItems: "center" }}>
                            <Text
                              style={{
                                fontSize: 18,
                                fontWeight: 500,
                                color:
                                  formaPagamentoSelecionada == forma.id
                                    ? "#FFFFFF"
                                    : "#01AC8A",
                              }}
                            >
                              {forma.nome}
                            </Text>
                            <Text
                              style={{
                                color:
                                  formaPagamentoSelecionada == forma.id
                                    ? "#FFFFFF"
                                    : "#01AC8A",
                                fontSize: 12,
                              }}
                            >
                              {forma.descricao}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    ))}
                </View>
              </View>
            )}

            <View style={{ padding: 24 }}>
              {valorTotal.pix == 0 && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontSize: 24, color: "#68717A" }}>
                    Sua compra saiu
                  </Text>
                  <Text
                    style={{ fontSize: 36, color: "#68717A", marginBottom: 40 }}
                  >
                    GRÁTIS
                  </Text>

                  <Image
                    style={{
                      flex: 1,
                      width: 644 / 2.5,
                      height: 673 / 2.5,
                      resizeMode: "contain",
                    }}
                    source={{
                      uri: FeelingHappy,
                    }}
                  />

                  <Text
                    style={{ fontSize: 24, color: "#68717A", marginTop: 40 }}
                  >
                    É só confirmar e aproveitar
                  </Text>
                </View>
              )}
              {valorTotal.pix > 0 && (
                <View>
                  {formaPagamentoSelecionada == "cartao-credito" && (
                    <PagamentoCartaoCredito
                      confirmarValoresDoacao={confirmarValoresDoacao}
                    />
                  )}
                  {/*formaPagamentoSelecionada == 2 && <PagamentoDebitoElo />*/}
                  {formaPagamentoSelecionada == "pix" && (
                    <PagamentoPIX
                      confirmarValoresDoacao={confirmarValoresDoacao}
                    />
                  )}
                  {formaPagamentoSelecionada > 3 && <Text> Erro</Text>}
                </View>
              )}
              {erroPagamento && (
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "red",
                    borderRadius: 5,
                    marginTop: 10,
                    padding: 10,
                    textAlign: "center",
                  }}
                >
                  <Text style={{ color: "red" }}>{erroPagamento}</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default Pagamento;

import API from './API'

export async function buscarEvento(token) {
    try {
        let resposta = await API.get(`/eventos/checkout_token/${token}`)

        let evento = resposta.data

        //console.log("EVENTO RECEBIDO DA API", evento)

        return evento
    } catch (error) {
        //console.log(`ERROR`, error.status)
        throw error
    }
}
import React from 'react'
import { View, Text, CheckBox as CB } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

const CheckBox = ({ onValueChange, value, text, textView, textTouchable, styleView, ...rest }) => {

    const Txt = (txt) => {
        return <Text style={{ marginLeft: 8 }}>{txt}</Text>
    }

    return (
        <View style={[{ flexDirection: 'row' }, styleView]}>
            <CB
                value={value}
                onValueChange={onValueChange}
                onCheckColor={'#FF0000'}
                {...rest}
            />
            {
                textView ? textView :
                    ((textTouchable == undefined || textTouchable) ?
                        <TouchableOpacity onPress={() => { onValueChange(!value) }}>{Txt(text)}</TouchableOpacity>
                        : Txt(text))
            }
        </View>
    )
}

export default CheckBox
import React, { useState, createContext, useEffect } from 'react'

const LightboxContext = createContext({})

export const LightboxProvider = ({ children }) => {
    const [pagina, setPagina] = useState("ingressos")
    const [lightboxOpened, setLightboxOpened] = useState(true)
    const [loading, setLoadingUse] = useState({ status: true, text: "Estamos quase lá" })
    const [error, setErrorUse] = useState({ status: false, text: "" })
    const [tokenLightbox, setTokenLightbox] = useState(null)
    const [isLightbox, setIsLightbox] = useState(false)
    const [showHeader, setShowHeader] = useState(true)
    const [reconhecimentoOpen, setReconhecimentoOpen] = useState(false)

    const setLoading = (status, text = "Carregando") => setLoadingUse(() => { return { status: status, text: text } })
    const setError = (status, text = "Ocorreu um erro") => setErrorUse(() => { return { status: status, text: text } })

    useEffect(() => {
        console.log("Init application - 1.0.0")
    }, [])

    useEffect(() => {
        if (error?.status) {
            setLoadingUse(loading => {
                return { ...loading, status: false }
            })
        }
    }, [error])

    const fecharLightbox = () => {
        setLightboxOpened(false)
        setPagina("ingressos")

        let json = {
            action: "close-lightbox-checkout",
            value: true
        }

        window.top.postMessage(JSON.stringify(json), "*")
    }


    return (
        <LightboxContext.Provider value={{
            pagina, setPagina,
            lightboxOpened, fecharLightbox,
            loading, setLoading,
            error, setError,
            tokenLightbox, setTokenLightbox,
            isLightbox, setIsLightbox,
            showHeader, setShowHeader,
            reconhecimentoOpen, setReconhecimentoOpen
        }}>
            {children}
        </LightboxContext.Provider>
    )
}

export default LightboxContext
import React, { useRef, useEffect, useState, useContext } from "react";
import {
  View,
  Text,
  Button,
  Image,
  ActivityIndicator,
  Dimensions,
  ScrollView,
} from "react-native";
import { Form } from "@unform/mobile";
import Input from "../../components/Form/input";
import { isBrowser, isMobile } from "react-device-detect";

import * as Linking from "expo-linking";

import LightboxContext from "../../contexts/lightbox";

import MyUniverse from "../../../assets/images/my-universe.svg";

import styles from "./styles";
import Colors from "../../values/colors";

import * as Yup from "yup";
import API from "../../services/API";
import AuthContext from "../../contexts/auth";
import CompraContext from "../../contexts/compra";
import { TouchableOpacity } from "react-native-gesture-handler";
import Avatar from "../../components/Avatar";
import TicketLayout from "../../components/TicketLayout";
import EventoContext from "../../contexts/evento";
import Icon from "react-native-vector-icons/FontAwesome";
import TitularesContext from "../../contexts/titulares";
import InputMask from "../../components/Form/inputMask";
import { calcDateDiff, validCPF } from "@renegoncalves/rg-helper";
import { validatePhone } from "validations-br";
import moment from "moment";
import PickerSelect from "../../components/Form/select";

const getDimensiosImage = ({ width, height }) => {
  let scale = 1;

  if (height < 900) scale = 1.25;
  if (height < 800) scale = 1.5;
  if (height < 700) scale = 1.75;
  if (height < 600) scale = 2;
  if (height < 500) scale = 3.5;
  if (height < 400) scale = 4;

  let finalWidth = 586.2920625 / scale;
  let finalHeight = 480.337665 / scale;

  return {
    width: finalWidth,
    height: finalHeight,
  };
};

const SelecionarTitular = () => {
  const { pagina, setPagina } = useContext(LightboxContext);
  const { pessoa, setPessoa, setTokenAuth, logout } = useContext(AuthContext);
  const { reservarIngressos, carrinho } = useContext(CompraContext);
  const { evento } = useContext(EventoContext);
  const { adicionarTitular, titulares, setTitulares } =
    useContext(TitularesContext);

  const formRef = useRef(null);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [titulares, setTitulares] = useState([]);

  const [numEtapas, setNumEtapas] = useState(1);
  const [etapaAtual, setEtapaAtual] = useState(0);
  const [titularAtual, setTitularAtual] = useState(null);

  const [errorEtapa, setErrorEtapa] = useState(null);

  const [ingressosNaoTitulados, setIngressosNaoTitulados] = useState([]);

  useEffect(() => {
    if (carrinho) {
      setIngressosNaoTituladosByCarrinho();
    }
  }, [carrinho]);

  const setIngressosNaoTituladosByCarrinho = () => {
    let ingressos = [];

    carrinho.forEach((item) => {
      for (let i = 0; i < item.quantidade; i++) {
        ingressos.push({
          ingresso: item,
          titular: null,
          checked: false,
          error: false,
        });
      }
    });
    setTitulares(ingressos);
  };

  const handleSubmit = async (titularesReserva) => {
    console.log(`handleSubmit`, titularesReserva);

    // try {
    //   setErrorLogin(null);
    //   setLoadingLogin(true);
    //   const schema = Yup.object().shape({
    //     login: Yup.string().required("Campo obrigatório"),
    //     senha: Yup.string().required("Campo obrigatório"),
    //   });

    //   await schema.validate(data, {
    //     abortEarly: false,
    //   });

    //   formRef.current.setErrors({});

    //   let dados = {
    //     login: data.login,
    //     senha: data.senha,
    //     tipo: "email",
    //   };

    //   let loginResponse = await API.post("/auth/sessao/login", dados);
    //   let tokenSessao = loginResponse.data;

    //   API.setHeader("x-access-token", tokenSessao.token);
    //   let pessoaResponse = await API.get("/pessoas/sessao");
    //   let pessoa = pessoaResponse.data;

    //   setTokenAuth(tokenSessao.token);
    //   setPessoa(pessoa);

    //   reservarIngressos();
    // } catch (error) {
    //   setLoadingLogin(false);
    //   //console.log("ERRO", error)
    //   if (error instanceof Yup.ValidationError) {
    //     const errorMessages = {};

    //     error.inner.forEach((err) => {
    //       errorMessages[err.path] = err.message;
    //     });

    //     formRef.current.setErrors(errorMessages);
    //   } else {
    //     setErrorLogin(error.data ? error.data : "Erro no login");
    //   }
    // }
  };

  const handleCheckDuplicatedCPF = async (cpf, evento_etapa_id, index) => {
    try {
      console.log("handleCheckDuplicatedCPF titulares", titulares);
      let duplicado = false;
      titulares.forEach((titular, i) => {
        if (titular.titular && titular.titular.cpf === cpf && index != i) {
          duplicado = true;
        }
      });

      if (duplicado) throw "Você já selecionou esse titular em outro ingresso";

      try {
        await API.post(`/ingressos/titulares/verificar/cpf`, {
          cpf,
          evento_etapa_id,
        });
      } catch (error) {
        console.error(error)
        console.error(error?.data)
        throw typeof error?.data == "string" ? error.data : "Ocorreu um erro inesperado. Aguarde alguns segundos e tente novamente!"
      }

      return false;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleNextEtapa = async (data) => {
    try {
      console.log(data);
      setLoading(() => true);

      setErrorEtapa(null);
      //verificar schema do formulario

      formRef.current.setErrors({});

      data.data_nascimento = data.data_nascimento
        .split("/")
        .reverse()
        .join("-");
      data.cpf = data.cpf.split(".").join("").split("-").join("");
      data.celular = data.celular
        .split("(")
        .join("")
        .split(")")
        .join("")
        .split("-")
        .join("")
        .split(" ")
        .join("");

      let shape = {
        nome: Yup.string()
          .test(
            "nome-sobrenome",
            "Nome e sobrenome obrigatórios",
            (value) => value.split(" ").length > 1
          )
          .required("Campo obrigatório!"),
        cpf: Yup.string()
          .test("is-cpf", "CPF inválido", (value) => validCPF(value))
          .required("Campo obrigatório!"),
        celular: Yup.string()
          .test("is-phone", "Celular inválido", (value) => validatePhone(value))
          .required("Campo obrigatório!"),
        email: Yup.string()
          .email("E-mail inválido")
          .required("Campo obrigatório!"),
        data_nascimento: Yup.string()
          .test("is-date-valid", "Data inválida", (value) => {
            const diff = calcDateDiff(value, moment(), "years");
            return !isNaN(Date.parse(value)) && diff < 0 && diff > -120;
          })
          .required("Campo obrigatório!"),
      }

      let { campos_extras = [] } = titulares[etapaAtual]?.ingresso?.lote?.setor

      let extrasShape = {}

      for (let campo_extra of campos_extras)
        extrasShape[campo_extra.nome] = Yup.string().required("Campo obrigatório!")

      if (campos_extras.length > 0)
        shape.extras = Yup.object().shape(extrasShape)

      const schema = Yup.object().shape(shape);

      await schema.validate(data, {
        abortEarly: false,
      });

      if (data.extras)
        data.extras = campos_extras?.map((campo_extra) => ({ nome: campo_extra.nome, valor: data.extras[campo_extra.nome] }))

      //verificar se cpf já foi selecionado
      try {
        if (!evento.permitir_cpf_duplicado)
          await handleCheckDuplicatedCPF(
            data.cpf,
            titulares[etapaAtual]?.ingresso?.lote?.setor?.evento_etapa,
            etapaAtual
          );
      } catch (error) {
        setErrorEtapa(
          typeof error == "string"
            ? error
            : "Você já selecionou esse titular em outro ingresso"
        );

        let newTitulares = [...titulares];
        newTitulares[etapaAtual] = {
          ...titulares[etapaAtual],
          checked: true,
          error: true,
        };

        setTitulares(newTitulares);
        return;
      }
      //verificar cpf no back

      // adiciona na lista
      let newTitulares = [...titulares];
      newTitulares[etapaAtual] = {
        ...titulares[etapaAtual],
        titular: data,
        checked: true,
        error: false,
      };

      setTitulares(newTitulares);

      console.log({ etapaAtual, newTitulares });
      if (etapaAtual + 1 < newTitulares.length) {
        handleChangeEtapa(etapaAtual + 1);
        return;
      }

      if (newTitulares.find((titular) => !titular.checked)) {
        setErrorEtapa("Você precisa selecionar todos os titulares");
        return;
      }

      let titularesReserva = newTitulares.map((t) => ({
        ...t.titular,
        lote_id: t?.ingresso?.lote?._id,
      }));

      reservarIngressos({ titulares: titularesReserva });
    } catch (error) {
      console.log("ERRO", error);
      // setError(error.data)
      // setLoading(false)

      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach((err) => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    } finally {
      setLoading(() => false);
    }
  };

  const handleChangeEtapa = (index) => {
    if (titulares[etapaAtual - 1]?.titular) {
      let titular = titulares[etapaAtual - 1].titular
      formRef.current.setData({
        ...titular,
        data_nascimento: moment(titular.data_nascimento).format("DD/MM/YYYY"),
        extras: titular.extras ? titular.extras?.reduce((accumulator, extra) => ({ ...accumulator, [extra.nome]: extra.valor }), {}) : undefined
      });
    } else {
      formRef.current.reset();
    }
    setEtapaAtual(index);
    formRef.current.setErrors({});
  };

  return (
    <View style={styles.container}>
      <Text
        style={{
          fontSize: 24,
          marginBottom: isMobile ? 2 : 10,
          textAlign: "center",
        }}
      >
        Selecionar Titular
      </Text>

      {errorEtapa && (
        <Text
          style={{
            fontSize: 16,
            marginBottom: isMobile ? 2 : 10,
            textAlign: "center",
            color: "red",
          }}
        >
          {errorEtapa}
        </Text>
      )}

      {titulares.length > 1 && (
        // <ScrollView
        //   style={{
        //     width: "100%",
        //     textAlign: "center",
        //     paddingTop: 10,
        //   }}
        // >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {titulares.map((ingresso, i) => (
            <View
              key={i}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <TouchableOpacity
                style={{
                  width: 30,
                  height: 30,
                  backgroundColor: etapaAtual == i ? "#53D769" : "#017781",
                  borderRadius: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={() => handleChangeEtapa(i)}
              >
                {ingresso.checked === true && (
                  <View
                    style={{
                      position: "absolute",
                      top: -5,
                      right: -5,
                      padding: 2,
                      backgroundColor: "#ffffff",
                      borderRadius: 15,
                    }}
                  >
                    <Icon
                      name={ingresso.error ? "times" : "check"}
                      color={ingresso.error ? "red" : "#53D769"}
                      size={12}
                    />
                  </View>
                )}
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "500",
                    color: "#ffffff",
                  }}
                >
                  {i + 1}
                </Text>
              </TouchableOpacity>

              {i < titulares.length - 1 && (
                <View
                  style={{
                    width: 20,
                    height: 1,
                    backgroundColor: "#017781",
                  }}
                ></View>
              )}
            </View>
          ))}
        </View>
        // </ScrollView>
      )}

      <SelecionarTitularIngresso
        evento={evento}
        ingresso={titulares[etapaAtual]?.ingresso}
        formRef={formRef}
        handleSubmit={handleNextEtapa}
        error={errorEtapa}
        etapaAtual={etapaAtual}
        numEtapas={titulares.length}
        loading={loading}
      />
    </View>
  );
};

export default SelecionarTitular;

const SelecionarTitularIngresso = ({
  ingresso,
  evento,
  formRef,
  handleSubmit,
  error,
  etapaAtual,
  numEtapas,
  loading,
}) => {
  return (
    <ScrollView
      style={{
        height: isMobile ? 300 : "100%",
        textAlign: "center",
        paddingTop: 10,
      }}
    >
      <Text
        style={{
          fontSize: 18,
          marginBottom: isMobile ? 2 : 10,
          marginTop: isMobile ? 2 : 10,
          textAlign: "center",
        }}
      >
        Etapa - {etapaAtual + 1} de {numEtapas}
      </Text>

      <View
        style={{
          alignItems: "center",
          marginBottom: isMobile ? 2 : 20,
        }}
      >
        <TicketLayout ingresso={ingresso} evento={evento} />
      </View>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="nome"
          placeholder="Nome"
          label="Nome"
          //size={!isMobile ? 22 : 14}
          color={"#313131"}
        />
        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 0 : 20,
          }}
        >
          <InputMask
            type="cpf"
            name="cpf"
            keyboardType="numeric"
            label="CPF"
            placeholder="CPF"
          />

          <InputMask
            type="cel-phone"
            name="celular"
            keyboardType="numeric"
            label="Celular"
            placeholder="Celular"
          />
        </View>
        <View
          style={{
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 0 : 20,
          }}
        >
          <InputMask
            type="custom"
            options={{ mask: "99/99/9999" }}
            name="data_nascimento"
            label="Data de Nascimento"
            placeholder="Data de Nascimento"
          />

          <Input
            name="email"
            placeholder="E-mail"
            label="E-mail"
            size={22}
            color={"#313131"}
          />
        </View>

        {ingresso?.lote?.setor?.campos_extras?.map(({ nome, titulo, tipo, opcoes }, index) =>
          tipo == "text" ?
            <Input
              name={`extras.${nome}`}
              placeholder={nome}
              label={titulo}
              size={22}
              color={"#313131"}
            /> :
            tipo == "select" ?
              <View style={{ marginBottom: index >= (ingresso?.lote?.setor?.campos_extras?.length - 1) ? 15 : 10 }}>
                <PickerSelect
                  name={`extras.${nome}`}
                  items={opcoes.map(({ value, text: label }) => ({ label, value }))}
                  label={titulo}
                // onValueChange={(value) => {
                //   setParcelaSelecionada(parseInt(value));
                // }}
                />
              </View> :
              <></>
        )}

        <Button
          title={
            loading
              ? "Carregando..."
              : etapaAtual + 1 < numEtapas
                ? "Próximo"
                : "Reservar Ingressos"
          }
          onPress={() => formRef.current.submitForm()}
          color="#04AD8A"
          disabled={loading}
        />
      </Form>
    </ScrollView>
  );
};

import { StyleSheet, Dimensions } from 'react-native';
import { isBrowser, isMobile } from 'react-device-detect';

//console.log(`isBrowser`, isBrowser)
//console.log(`isMobile`, isMobile)
const { height, width } = Dimensions.get('window');
const aspectRatio = height / width;

const styles = StyleSheet.create({
  lightbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    flex: 1,
    backgroundColor: 'rgba(4, 173, 138, 0.6)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: Object.assign(
    {},
    {
      backgroundColor: '#fff',
      flexDirection: 'column',
    },
    isMobile
      ? {
          // Smartphone
          width: Dimensions.get('window').width,
          height: '80%',
        }
      : {
          // PC
          width: '80%',
          height: '80%',
          minHeight: Dimensions.get('window').height * 0.8,
          borderRadius: 10,
        }
  ),
  body: {
    flex: 1,
    height: Dimensions.get('window').height * 0.8 - 175,
    width: '100%',
  },
  footer: {},
  copyright: {
    flexDirection: 'row',
    color: '#FFF',
    marginTop: 15,
    alignItems: 'center',
  },
});

export default styles;

import { StatusBar } from "expo-status-bar";
import {
    StyleSheet,
    Text,
    Touchable,
    View,
    TouchableOpacity,
    ScrollView,
    TextInput,
    Dimensions,
} from "react-native";

import React, { useEffect, useState, useRef } from "react";
import { useField } from '@unform/core'
import Colors from "../../values/colors";

export default function SelectAutocomplete({
    label,
    labelType = "outlined",
    onChangeText = () => { },
    emptyText = "Nenhum item encontrado",
    name,
    errorMessage,
    styleError,
    ...prop
}) {
    const inputRef = useRef(null)
    const { fieldName, registerField, defaultValue, error } = useField(name)

    const [attResonId, setAttResonId] = useState();
    const [arr, setArr] = useState(null);
    const [empty, setEmpty] = useState(false);
    let leaveType;
    const arryFilter = (str) => {
        if (arr == null || !str) {
            setArr(prop && prop.data);
        } else {
            leaveType =
                prop &&
                prop.data.filter((leaveType) => {
                    if (!leaveType.title.toUpperCase().indexOf(str.toUpperCase())) {
                        return true;
                    }
                });
            setArr(leaveType);
        }
    };
    const [leaveVal, setLeaveVal] = useState("");
    const [leaveFlag, setLeaveFlag] = useState(false);
    const [attTypeId, setAttTypeId] = useState();
    const [absenceTypeBorderBottomColor, setAbsenceTypeBorderBottomColor] =
        useState("black");
    const onTrigger = (attTypeId) => {
        prop.parentCallback(attTypeId);
    };

    const [inField, setInField] = useState(false)

    const [isFocus, setIsFocus] = useState(false)
    const setFocus = (bool) => {
        setIsFocus(bool)
    }

    useEffect(() => {
        arryFilter("")
    }, [prop.data])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            getValue: ref => {
                return attTypeId || ""
            },
        })
    }, [fieldName, registerField, leaveVal])

    return (
        <View style={[styles.textInputView, error || errorMessage || (error && isFocus) ? { borderColor: Colors.error, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { marginBottom: 8 }, isFocus ? { borderColor: Colors.primary, borderWidth: 2 } : {}]}>
            {label && labelType == "outlined" && <Text
                style={[styles.labelInner]}
            >{label}</Text>}

            <View
                style={{
                    alignSelf: "center",
                    alignContent: "center",
                    width: "100%",
                    // position: "absolute",
                    ...styles.textInputView
                }}
            >
                <TextInput
                    ref={inputRef}
                    editable
                    value={leaveVal}
                    // placeholderTextColor={prop.placeholderTextColor}
                    style={styles.textInput}
                    selectionColor={"#006e51"}
                    placeholder={prop.placeholder}
                    onChangeText={(text) => {
                        setLeaveVal(text);
                        setLeaveFlag(true);
                        arryFilter(text);
                        if (onChangeText)
                            onChangeText(text)
                    }}
                    onFocus={() => {
                        setFocus(() => true)
                        setLeaveFlag(true);
                        arryFilter();
                        // setAbsenceTypeBorderBottomColor("#006e51");
                    }}
                    onBlur={() => {
                        setFocus(() => false)
                        // setLeaveFlag(false);
                        // prop &&
                        //     prop.data.map((item, index) => {
                        //         if (leaveVal.toUpperCase() == item.title.toUpperCase()) {
                        //             setAttTypeId(item.id);
                        //             onTrigger(item);
                        //         }
                        //     });
                        // setAbsenceTypeBorderBottomColor("black");

                    }}
                />

                <View
                    style={{
                        // position: "absolute",
                        // top: 50,
                        // height: 135,
                        elevation: 100,
                        zIndex: 1000,
                        // backgroundColor: "red"
                    }}
                >
                    <ScrollView
                        style={{}}
                        keyboardShouldPersistTaps={"always"}
                        nestedScrollEnabled={true}
                    >
                        {/* {console.log(arr && arr.length)} */}
                        {leaveFlag
                            ? arr.map((item, index) => {
                                return (
                                    <View
                                        key={index}
                                        style={{
                                            width: "100%",
                                            // prop.widthBySide &&
                                            // Dimensions.get("screen").width - prop.widthBySide,
                                            height: 40,
                                            // borderRadius: prop.borderRadiusList,
                                            marginTop: prop.listMarginTop,
                                            padding: 7,
                                            elevation: 10,
                                            borderBottomWidth: 0.5,
                                            backgroundColor: prop.listBackgroundColor
                                        }}
                                    >
                                        <TouchableOpacity
                                            onPress={() => {
                                                setLeaveVal(item.title);
                                                setAttTypeId(item.id);
                                                setLeaveFlag(false);
                                                onTrigger(item);
                                            }}
                                        >
                                            <View>
                                                <Text
                                                    key={index}
                                                    style={{
                                                        color: prop.listTextColor,
                                                    }}
                                                >
                                                    {item.title}
                                                </Text>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                );
                            })
                            : null}
                        {leaveFlag && arr && arr.length == 0 ? (
                            <Text
                                style={{
                                    width:
                                        prop.widthBySide &&
                                        Dimensions.get("screen").width - prop.widthBySide,
                                    height: 40,
                                    borderRadius: 1,
                                    padding: 7,
                                    elevation: 2,
                                    backgroundColor: "white",
                                    textAlign: "center",
                                }}
                            >
                                {emptyText}
                            </Text>
                        ) : null}
                    </ScrollView>
                </View>
            </View>

            {(error || errorMessage) &&
                <View style={{ backgroundColor: Colors.error, padding: 5, borderBottomLeftRadius: 3, borderBottomRightRadius: 3 }}>
                    <Text style={[{ color: '#FFF', fontSize: 12 }, styleError]}>{error || errorMessage}</Text>
                </View>
            }
        </View>
    );
}

const styles = StyleSheet.create({
    label: {
        color: "#152536",
        fontSize: 14
    },
    labelInner: {
        color: "#152536",
        fontSize: 12,
        marginTop: -5,
        marginLeft: 10,
        position: 'absolute',
        backgroundColor: '#FFF',
        paddingLeft: 5,
        paddingRight: 5
    },
    textInputView: {
        borderWidth: 1,
        borderColor: "#CED4DA",
        borderRadius: 3,
        marginTop: 8,
    },
    textInput: {
        padding: 10,
        paddingTop: 15
    },
});
import { StyleSheet, Dimensions } from 'react-native'

const styles = StyleSheet.create({
    container: {
        width: 400,
        minHeight: 120,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: 'rgba(51, 51, 51, 0.3)',
        marginBottom: 20,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    ingresso: {
        flex: 1,
        flexDirection: 'row',
    }
});

export default styles
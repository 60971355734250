import React from 'react'
//import API from './API'
import Script from '@gumgum/react-script-tag';

import { Logger } from '../../../../helpers/Logger'

/*
    1. Inclua a biblioteca MercadoPago.js
    2. Adicione o formulário de pagamento
    3. Configure sua chave pública
    4. Obtenha os dados para seu formulário
    5. Crie o token do cartão
*/

const MercadoPago = () => {
    // Obtenha os tipos de documento
    const getIdentificationTypes = async () => {
        let tipos = window.Mercadopago.getIdentificationTypes();
        //Logger.log("{ getIdentificationTypes }", tipos)
        return tipos
    }

    // Obtenha o método de pagamento do cartão
    const getPaymentMethod = async (cardNumber) => {
        return new Promise((resolve, reject) => {
            if (cardNumber.length >= 6) {
                let bin = cardNumber.substring(0, 6);
                window.Mercadopago.getPaymentMethod({
                    "bin": bin
                }, (status, response) => {
                    if (status == 200) {
                        let paymentMethod = response[0]
                        resolve(paymentMethod)
                    } else {
                        reject(`Erro: ${response}`)
                    }
                });
            }
        })
    }

    // Obtenha a quantidade de parcelas disponíveis
    const getInstallments = async (paymentMethodId, transactionAmount, issuerId, taxaDefinida = null) => {
        return new Promise((resolve, reject) => {
            window.Mercadopago.getInstallments({
                "payment_method_id": paymentMethodId,
                "amount": parseFloat(transactionAmount),
                "issuer_id": parseInt(issuerId)
            }, (status, response) => {
                if (status == 200) {
                    //Logger.log("RESPONSE QUE EU QUERO 2", response)

                    let parcelas = []
                    response[0].payer_costs.forEach(parcela => {
                        parcelas.push({
                            parcela: parcela.installments,
                            valor: parcela.installment_amount,
                            taxa: parcela.installment_rate,
                            valor_total: parcela.installment_amount + parcela.installment_rate,
                            string: parcela.recommended_message
                        })
                    })

                    resolve(parcelas)
                    /*document.getElementById('installments').options.length = 0;
                    response[0].payer_costs.forEach(payerCost => {
                        let opt = document.createElement('option');
                        opt.text = payerCost.recommended_message;
                        opt.value = payerCost.installments;
                        document.getElementById('installments').appendChild(opt);
                    });*/
                } else {
                    reject(`installments method info error: ${response}`);
                }
            });
        })
    }

    // Crie o token do cartão
    const getCardToken = async (docType, docNumber, cardholderName, cardNumber, cardExpiration, securityCode) => {
        return new Promise((resolve, reject) => {
            let dados = {
                docType: docType,
                docNumber: docNumber?.split(".")?.join("")?.split("-")?.join(""),
                cardholderName: cardholderName,
                cardExpiration: cardExpiration,
                cardNumber: cardNumber,
                securityCode: securityCode
            }

            window.Mercadopago.createToken(dados, (status, response) => {
                if (status == 200 || status == 201) {
                    //Logger.log("RESPONSE createToken", response)
                    resolve(response)
                } else {
                    //Logger.log("Verify filled data!", response);
                    reject(response)
                }
            });
        })
    }

    const initPosLoad = () => {
        //let publicKey = "TEST-1053eb4e-bd65-46bf-b55d-577af2e5f0ec" // Teste
        let publicKey = "APP_USR-4e3ac8c1-6abb-4ac0-82d9-fc05ce353ae7" // Produção
        window.Mercadopago.setPublishableKey(publicKey);

        let tipos = window.Mercadopago.getIdentificationTypes();
        //Logger.log("window.Mercadopago.getIdentificationTypes()", tipos)
    }

    const getScript = () => {
        return <Script
            src="https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js"
            type="text/javascript"
            onLoad={() => { initPosLoad() }}
            onError={() => { alert("error mercadoPago") }}
            async
        />
    }

    return { getScript, getIdentificationTypes, getPaymentMethod, getInstallments, getCardToken }
}
export default MercadoPago
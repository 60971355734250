import React, { useState, useCallback, forwardRef, useRef, useEffect } from 'react';
import { TextInputMask } from 'react-native-masked-text';
import Input from './input';
import { useField } from '@unform/core';

const InputMask = ({ type, name, ...rest }) => {
    const inputRef = useRef(null)

    const { fieldName, registerField, defaultValue, error } = useField(name)

    const [text, setText] = useState('');
    const [rawText, setRawText] = useState('');

    const handleChangeText = useCallback((maskedText, unmaskedText) => {
        setText(maskedText);
        setRawText(unmaskedText);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: ref => {
                return rawText
            },
            setValue: (ref, value) => {
                console.log(value)
            },
            clearValue: ref => {
                console.log("clear")
                setText("")
                setRawText("")
            },
        })
    }, [fieldName, registerField, inputRef, rawText])

    const customGetValue = () => {
        console.log("customGetValue", rawText)
        return rawText
    }

    const customSetValue = (value) => {
        console.log("customSetValue", value)
        setRawText(value)
        setText(value)
    }

    return (
        <TextInputMask
            type={type}
            name={name}
            includeRawValueInChangeText
            value={text}
            onChangeText={handleChangeText}
            customTextInput={Input}
            customTextInputProps={{
                ref: inputRef,
                rawText,
                onInitialData: setText,
                customSetValue,
                customGetValue
            }}
            // refInput={inputRef}
            {...rest}
        />
    );
};

export default forwardRef(InputMask);
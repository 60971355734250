import React, { useRef } from 'react'
import { View, TouchableOpacity, Text, Clipboard } from 'react-native'
import { Form } from '@unform/mobile'
import Input from '../../components/Form/input'
import Colors from '../../values/colors'
import Toast from "react-native-fast-toast";

const InputCopy = ({ textToCopy, styleForm }) => {
    const sombra = { shadowColor: "#000", shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5, }

    const formRef = useRef(null)
    const toast = useRef(null);

    const copiar = () => {
        Clipboard.setString(textToCopy)

        toast.current.show("Link copiado");
    }

    return (
        <TouchableOpacity onPress={() => { copiar() }}>
            <Form ref={formRef} style={[{ height: 40, flexDirection: 'row', marginVertical: 5 }, styleForm]}>
                <Input labelType="flat" name="link" styleInput={[{ marginTop: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }, sombra]} defaultValue={textToCopy} styleInputView={{ marginTop: 0 }} styleView={{ flex: 1 }} disabled />
                <View
                    style={[{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5, backgroundColor: Colors.primary, height: 43, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 5 }, sombra]}
                    onPress={() => { copiar() }}>
                    <Text style={{ color: '#FFF' }}>Copiar</Text>
                </View>
            </Form>
            <Toast ref={toast} />
        </TouchableOpacity>
    )
}

export default InputCopy
import React, { useContext, useEffect, useState } from "react"

import styles from "./styles"
import AuthContext from "../../contexts/auth"

import { View, Text } from "react-native";
import LightboxContext from "../../contexts/lightbox";
import { TouchableHighlight } from "react-native-gesture-handler";
import Icon from 'react-native-vector-icons/FontAwesome5';

export default function IframeReconhecimento({
    open,
    close
}) {
    const { tokenAuth } = useContext(AuthContext)

    if (!open || !tokenAuth) return <></>

    return (
        <View style={styles.container}>
            <View style={styles.closeButton}>
                <TouchableHighlight
                    onPress={close}
                >
                    <Icon
                        name={"times"}
                        color="#FFFFFF"
                        style={{ fontSize: 24 }}
                    />
                </TouchableHighlight>
            </View>

            <iframe
                src={`https://ipass.ivve.tech/?${tokenAuth ? `&session_token=${tokenAuth}` : ""}`}
                style={{ width: "100%", height: "100%", border: 0 }}
            />
        </View>
    )
}
import 'react-native-gesture-handler';
import React, { useEffect, useContext } from 'react';
import { View } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';

import Lightbox from '../Lightbox'

import API from '../../services/API'

import url from 'url'

// Contexts
import LightboxContext from '../../contexts/lightbox';

import { buscarEvento } from '../../services/evento'

// Contexts
import EventoContext from '../../contexts/evento';
import CompraContext from '../../contexts/compra';
import AuthContext from '../../contexts/auth';

const LightboxCheckout = () => {
    const { setPagina, lightboxOpened, setError, setLoading, setTokenLightbox, setIsLightbox, setShowHeader } = useContext(LightboxContext)
    const { setEvento } = useContext(EventoContext)
    const { addCarrinho, setTokenReserva, setReserva, setModoPagamento, setModoCompra, setDataHoraLimite } = useContext(CompraContext)
    const { setTokenAuth } = useContext(AuthContext)

    var checkoutToken
    var reserveToken
    var keyStorage

    useEffect(() => {
        getURLParams()
    }, [])

    const getURLParams = () => {
        let urlObject = url.parse(window.location.href, true)

        start(urlObject.query)

        // Lightbox
        /*if (urlObject.query["checkout_token"]) {
            checkoutToken = urlObject.query["checkout_token"]
            keyStorage = `iPass-LB-token-reserva-${checkoutToken}`
            //console.log("TOKEN", checkoutToken)
            setTokenLightbox(checkoutToken)
            getEvento(checkoutToken)

            if (urlObject.query["rt"]) { // Reserve token
                reserveToken = urlObject.query["rt"]
                getReserva(reserveToken)
                //keyStorage = `iPass-LB-token-reserva-${checkoutToken}`
                //console.log("TOKEN", checkoutToken)
                //setTokenLightbox(checkoutToken)
                //getEvento(checkoutToken)
                setModoPagamento(true)
                setShowHeader(false)
            }
        } else {
            if (urlObject.query["rt"]) { // Reserve token
                reserveToken = urlObject.query["rt"]
                getReserva(reserveToken, true)
                //keyStorage = `iPass-LB-token-reserva-${checkoutToken}`
                //console.log("TOKEN", checkoutToken)
                //setTokenLightbox(checkoutToken)
                //getEvento(checkoutToken)
                setModoPagamento(true)
                setShowHeader(false)
            } else {
                setError(true, "Evento não informado")
                setLoading(false)
                return
            }
        }

        // Sessão
        if (urlObject.query["session_token"]) {
            let sessionToken = urlObject.query["session_token"]
            keyStorage = `iPass-LB-token-reserva-${checkoutToken}`
            //console.log("SESSION TOKEN", sessionToken)
            setTokenAuth(sessionToken)
        }*/
    }

    // Configurações iniciais
    const start = async ({ lb = "true", header, application_token, checkout_token, session_token, reserve_token, rt }) => {
        try {
            // console.log("start", { lb, header, application_token, checkout_token, session_token, reserve_token, rt })

            if (application_token && API?.axiosInstance?.defaults?.params?.token)
                API.axiosInstance.defaults.params.token = application_token

            // Lightbox
            if (lb == "true")
                setIsLightbox(true)

            if (header == "false")
                setShowHeader(false)

            if (reserve_token || rt) {
                let reserva = null
                if (rt) {
                    try {
                        let response = await API.get(`/compras/reservas/uuid/${rt}`)
                        reserva = response.data
                        console.log(reserva)
                        reserve_token = reserva.token
                        setDataHoraLimite(reserva.datahora_limite)
                        setModoCompra("link")
                    } catch (error) {
                        throw { message: "A reserva não existe ou expirou" }
                    }
                }

                setTokenReserva(reserve_token)
                setModoPagamento(true)
                setShowHeader(false)
                getReserva(reserve_token, true, null, reserva)
            } else if (checkout_token) { // Token de etapa de evento
                setTokenLightbox(checkout_token)
                getEvento(checkout_token)
                setModoPagamento(false)
            } else
                throw { message: "Dados não informados" }

            if (session_token)
                setTokenAuth(session_token)
        } catch (error) {
            console.error(error)
            setError(true, error?.message || "Ocorreu um erro")
            setLoading(false)
        }
    }

    const getEvento = async (checkout_token) => {
        try {
            let event = await buscarEvento(checkout_token)
            console.log("EVENTO", event)
            setEvento(event)
            getReserva(null, false, checkout_token)
        } catch (error) {
            setError(true, "Erro na busca do evento")
            setLoading(false)
        }
    }

    const getReserva = async (tokenReserva = null, modoPagamento = false, checkoutToken, reserva = null) => {
        try {
            console.log("getReserva", tokenReserva, modoPagamento, checkoutToken)

            if (!tokenReserva) {
                tokenReserva = await AsyncStorage.getItem(`iPass-LB-token-reserva-${checkoutToken}`)
                //console.log("TOKEN RESERVA DO STORAGE", tokenReserva)

                setTokenReserva(tokenReserva)
            }

            if (tokenReserva || reserva) { // Tem token de reserva salvo no storage para esse evento
                try {
                    setLoading(true, "Verificando reservas")

                    let params = {}

                    if (modoPagamento) {
                        params.evento = true
                    }

                    if (!reserva) {
                        let response = await API.get(`/compras/reservas/${tokenReserva}`)
                        reserva = response.data
                        if (reserva)
                            setDataHoraLimite(reserva.datahora_limite)
                    }

                    console.log("RESERVA", reserva)

                    setReserva(() => reserva)

                    //console.log("RESERVA", reserva)
                    setTokenReserva(tokenReserva)

                    for (let ingresso of reserva.ingressos) {
                        //console.log("INGRESSO", ingresso)
                        addCarrinho(ingresso, ingresso.quantidade)
                    }

                    /*if (modoPagamento) {
                        let response = await API.get(`/eventos/checkout_token/${reserva.evento_checkout_token}`)
                        let ev = response.data
                        setEvento(ev)
                    }*/

                    setPagina("pagamento")
                    setLoading(false)
                } catch (error) {
                    console.error(error)
                    if (modoPagamento) {
                        console.error(error)
                        setError(true, "A reserva não existe ou expirou")
                    } else {
                        //console.log("ERROOOOOO", error)
                        if (error.status == 404) AsyncStorage.removeItem(`iPass-LB-token-reserva-${checkoutToken}`)
                        setPagina("ingressos")
                        setLoading(false)
                        setTokenReserva(null)
                    }
                }
            } else { // Não tem token de reserva salvo no storage
                setPagina("ingressos")
                setTokenReserva(null)
            }

            setLoading(false)
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    return (
        <View style={{ flex: 1, padding: 30 }}>
            {lightboxOpened && <Lightbox />}
        </View>
    );
}

export default LightboxCheckout
import { StyleSheet, Dimensions } from 'react-native'
import { isMobile } from "react-device-detect";

const styles = StyleSheet.create({
    container: Object.assign({},
        !isMobile ? {
            paddingHorizontal: 20
        } : {
                padding: 10
            }),
    pixContainer: Object.assign({},
        !isMobile ? {
            flexDirection: 'row'
        } : {
                flexDirection: 'column-reverse'
            },
        {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }),
    instrucao: {
        paddingVertical: 5,
        flex: 1
    }
});

export default styles
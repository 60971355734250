import API from './API'

export async function getToken(gateway, data) {
    try {
        let resposta = await API.post(`/pagamentos/tokens/${gateway}`, data)

        let token = resposta.data

        return token
    } catch (error) {
        //console.log(`ERROR`, error.status)
        throw error
    }
}
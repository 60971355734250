import React, { useContext } from 'react'
import { View, Text, Image } from 'react-native'
import * as Animatable from 'react-native-animatable'
import Icon from 'react-native-vector-icons/FontAwesome5';
import LightboxContext from '../../contexts/lightbox';

const AnimatedIcon = Animatable.createAnimatableComponent(Icon)

const Loading = ({ logo, text, styleText }) => {
    const { loading } = useContext(LightboxContext)

    return (
        <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            <View style={{ flex: 1, alignContent: 'center', alignItems: 'center' }}>
                {logo == true || logo == undefined && <Image style={{
                    width: 716 / 2.5,
                    height: 346 / 2.5,
                    marginBottom: 30
                }} source={{
                    uri: 'https://res.cloudinary.com/ipass/image/upload/v1639158715/logoipass_ay4gm2.png'
                }} />}

                {text || loading && <Text style={[{ fontSize: 16 }, styleText]}>{text ? text : loading.text}</Text>}

                <AnimatedIcon name="spinner" size={75} style={{ marginTop: 50 }} color="#000" animation="rotate" iterationCount={"infinite"} useNativeDriver />
            </View>
        </View>
    )
}

export default Loading
import React, { useState, useContext, useEffect } from "react";
import { View, Button, Text, ActivityIndicator } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { isBrowser, isMobile } from "react-device-detect";

import API from "../../services/API";

import { formatReal } from "../../helpers/Format";

// Styles
import styles from "./styles";

// Contexts
import LightboxContext from "../../contexts/lightbox";
import EventoContext from "../../contexts/evento";
import CompraContext from "../../contexts/compra";
import AuthContext from "../../contexts/auth";

const Footer = () => {
  const { pagina, setPagina, setLoading, setError } =
    useContext(LightboxContext);
  const { evento } = useContext(EventoContext);
  const {
    carrinho,
    valorTotal,
    quantidadeTotal,
    loadingConfirmacaoCompra,
    efetuarCompra,
    reservarIngressos,
    modoPagamento,
  } = useContext(CompraContext);
  const { tokenAuth, setPessoa } = useContext(AuthContext);

  const avancarIngressos = async () => {
    if (carrinho.length == 0) {
      alert("Nenhum ingresso selecionado");
    } else {
      setPagina("login");

      /*if (tokenAuth) {
                // Verificar login
                setLoading(true, "Verificando sessão")
                try {
                    API.setHeader("x-access-token", tokenAuth)
                    let responsePessoa = await API.get(`/pessoas/sessao`)
                    //console.log("RESPONSE PESSO", responsePessoa)
                    let pessoa = responsePessoa.data
                    setPessoa(pessoa)

                    await reservarIngressos()
                    setPagina("pagamento")
                } catch (error) {
                    API.deleteHeader("x-access-token")
                    setError(true, "Erro na verificação da sessão")
                    setLoading(false)
                }
            } else {
                setPagina("login")
            }*/
    }
  };

  const voltar = async () => {
    AsyncStorage.removeItem(`iPass-LB-token-reserva-${evento.etapa._id}`);
    if (["login", "selecionarTitular"].indexOf(pagina)) setPagina("ingressos");
    else if (pagina == "pagamento") setPagina("ingressos");
  };

  return (
    <View
      style={[
        styles.container,
        ["ingressos"].includes(pagina) && {
          height: 70,
        },
      ]}
    >
      {["ingressos"].includes(pagina) && (
        <View style={styles.lightbox_rota}>
          {valorTotal.pix == valorTotal["cartao-credito"] ||
          quantidadeTotal == 0 ? (
            <Text style={{ fontSize: 16, color: "#04AD8A", paddingBottom: 4 }}>
              {formatReal(valorTotal ? valorTotal.pix : 0)}
            </Text>
          ) : (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ fontSize: 18, color: "#04AD8A", paddingBottom: 4 }}
                >
                  {formatReal(valorTotal ? valorTotal.pix : 0)}
                </Text>
                <Text
                  style={{
                    paddingHorizontal: isMobile ? 4 : 8,
                    marginBottom: 3,
                    fontSize: 12,
                  }}
                >
                  ou
                </Text>
                <Text
                  style={{ fontSize: 14, color: "#04AD8A", paddingBottom: 4 }}
                >
                  {formatReal(valorTotal ? valorTotal["cartao-credito"] : 0)}
                </Text>
              </View>
              <Text
                style={{
                  marginBottom: 3,
                  fontSize: 12,
                }}
              >
                no Cartão de Crédito
              </Text>
            </View>
          )}
          <Text>{`${quantidadeTotal} ingresso${
            quantidadeTotal != 1 ? "s" : ""
          } selecionado${quantidadeTotal != 1 ? "s" : ""}`}</Text>
        </View>
      )}

      {["pagamento", "login", "selecionarTitular"].includes(pagina) &&
        modoPagamento == false && (
          <View style={{ minWidth: 200 }}>
            <Button
              title="Voltar"
              color="#04AD8A"
              style={styles.botao}
              onPress={() => {
                voltar();
              }}
            />
          </View>
        )}

      <View
        style={{
          position: "absolute",
          right: 8,
          minWidth: isMobile ? 100 : 200,
        }}
      >
        {["ingressos"].includes(pagina) && (
          <Button
            disabled={carrinho.length == 0}
            title="Avançar"
            color="#04AD8A"
            style={styles.botao}
            onPress={() => {
              avancarIngressos();
            }}
          />
        )}
        {pagina == "pagamento" && valorTotal.pix == 0 && (
          <Button
            title={
              loadingConfirmacaoCompra ? (
                <ActivityIndicator
                  animating={true}
                  size="small"
                  color="#00ff00"
                />
              ) : (
                "Confirmar Compra"
              )
            }
            color="#04AD8A"
            style={styles.botao}
            onPress={() => {
              efetuarCompra();
            }}
          />
        )}
      </View>
    </View>
  );
};

export default Footer;

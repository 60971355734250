import React, { useState, createContext, useEffect } from 'react'

const PagamentoContext = createContext({})

export const PagamentoProvider = ({ children }) => {
    const [pix, setPix] = useState(null)

    const [erroPagamento, setErroPagamento] = useState(null)
    const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] = useState(null)
    const [cartaoCreditoInserido, setCartaoCreditoInserido] = useState(null)

    return (
        <PagamentoContext.Provider value={{
            pix, setPix,
            erroPagamento, setErroPagamento,
            formaPagamentoSelecionada, setFormaPagamentoSelecionada,
            cartaoCreditoInserido, setCartaoCreditoInserido
        }}>
            {children}
        </PagamentoContext.Provider>
    )
}

export default PagamentoContext
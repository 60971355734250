import React, { useState, useEffect, useContext } from "react";
import { View, Text, Image, TouchableOpacity, Share } from "react-native";
import CardTicket from "../../../assets/Card_Ticket.png";
import MyUniverse from "../../../assets/images/my-universe.svg";
import styles from "./styles";

import { formatReal } from "../../helpers/Format";

export default function TicketLayout({ ingresso, style, evento }) {
  const [titular, setTitular] = useState(null);

  return (
    <TouchableOpacity onPress={() => {}}>
      <View style={{ ...styles.centerView, ...style }}>
        <View style={{ width: 300, height: 80, position: "relative" }}>
          <Image
            style={{ width: "100%", height: "100%" }}
            source={{
              uri: CardTicket,
            }}
          />
          <Image
            style={{
              position: "absolute",
              right: 0,
              height: 80,
              width: 250,
              aspectRatio: 1.91,
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
              zIndex: -1,
            }}
            source={{
              uri: evento ? evento?.path_imagem : ingresso?.evento?.path_imagem,
            }}
          />
        </View>

        <View style={styles.content}>
          {ingresso?.codigo && (
            <View
              style={{
                position: "absolute",
                left: -50,
                bottom: 4,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  lineHeight: 12,
                  fontSize: 12,
                  color: "#ffffff",
                  fontWeight: "400",
                }}
              >
                {ingresso.codigo.substring(0, 3)}
              </Text>
              <Text
                style={{
                  lineHeight: 12,
                  fontSize: 12,
                  color: "#ffffff",
                  fontWeight: "400",
                }}
              >
                {ingresso.codigo.substring(3, 6)}
              </Text>
            </View>
          )}

          <Text style={styles.ticketEventName}>
            {evento ? evento?.nome : ingresso?.evento?.nome}
          </Text>
          <Text style={styles.ticketType}>
            {ingresso?.lote.setor?.nome} -{" "}
            {ingresso?.lote?.nome || ingresso?.nome}
          </Text>

          <Text style={styles.ticketPrice}>
            {ingresso?.valor ? formatReal(ingresso.valor) : "GRÁTIS"}
          </Text>
          {(+ingresso?.valor_doacao_doe || titular?.valor_doacao) && (
            <Text style={styles.donatePrice}>
              {formatReal(+ingresso?.valor_doacao_doe || titular?.valor_doacao)}
            </Text>
          )}
        </View>

        {!ingresso?.envios && ingresso?.envios?.length == 0 && (
          <View
            style={{
              position: "absolute",
              top: 0,
              right: 10,
              backgroundColor: "yellow",
              paddingVertical: 3,
              paddingHorizontal: 10,
              borderRadius: 5,
            }}
          >
            <Text style={{ fontSize: 11, fontWeight: "400" }}>Não enviado</Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

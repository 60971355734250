import React, { useState, createContext, useEffect } from 'react'
import socketIOClient from "socket.io-client";
//import { REACT_APP_API_URL } from '@env'

import { Logger } from '../helpers/Logger'

const WebSocketContext = createContext({})

export const WebSocketProvider = ({ children }) => {
    var socket = null

    useEffect(() => {
        connect()
    }, [])

    const connect = () => {
        const sckt = socketIOClient("https://api.ipass.com.br"/*REACT_APP_API_URL*/, { path: '/config/socket.io' });
        socket = sckt
    }

    const onSocket = (evento, callback) => {
        //Logger.log("ON", evento)
        socket.on(evento, data => {
            //Logger.log("CHEGOU", evento)
            callback(data)
        })
    }

    return (
        <WebSocketContext.Provider value={{
            onSocket
        }}>
            {children}
        </WebSocketContext.Provider>
    )
}

export default WebSocketContext
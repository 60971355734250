import React, { useState, createContext, useContext, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import LightboxContext from "./lightbox";
import PagamentoContext from "./pagamento";
import EventoContext from "./evento";
import API from "../services/API";

const TitularesContext = createContext({});

export const TitularesProvider = ({ children }) => {
  const [titulares, setTitulares] = useState([]);

  const adicionarTitular = async (titular) => {
    setTitulares([...titulares, titular]);
  };

  return (
    <TitularesContext.Provider
      value={{
        titulares,
        setTitulares,
        adicionarTitular,
      }}
    >
      {children}
    </TitularesContext.Provider>
  );
};

export default TitularesContext;

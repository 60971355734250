import { StyleSheet, Dimensions } from 'react-native'
import Colors from '../../values/colors'

const styles = StyleSheet.create({
    container: {
        width: 300,
        marginLeft: 43,
        backgroundColor: Colors.primary,
        padding: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },
    input: {
        backgroundColor: '#FFF'
    }
});

export default styles
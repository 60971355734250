import { StyleSheet } from "react-native";

export default StyleSheet.create({
  centerView: {
    position: "relative",
    paddingTop: 5,
    paddingLeft: 5,
  },
  ticketCard: {
    width: 300,
  },
  content: {
    position: "absolute",
    top: 0,
    left: 65,
    height: 80,
    paddingVertical: 5,
    paddingLeft: 5,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  ticketName: {
    color: "#FFF",
    fontSize: 14,
    fontWeight: "300",
  },
  ticketCPF: {
    color: "#FFF",
    fontSize: 13,
    fontWeight: "200",
    marginTop: -2,
  },
  ticketEventName: {
    color: "#FFF",
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  ticketType: {
    color: "#FFF",
    fontSize: 9,
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: -3,
  },
  ticketPrice: {
    color: "#53D769",
    fontSize: 13,
    fontWeight: "bold",
  },
  donatePrice: {
    color: "#25B6F4",
    fontSize: 12,
    marginTop: -5,
    fontWeight: "bold",
  },
  ticketPurchaser: {
    color: "#FFF",
    fontSize: 9,
  },
  ticketPurchaserCPF: {
    color: "#FFF",
    fontSize: 9,
  },
  ticketComponent: {
    position: "absolute",
  },
  ticketEvent: {
    marginLeft: 164,
  },
  semTitular: {
    color: "#FF555A",
    fontWeight: "bold",
    fontSize: 14,
  },
});

import { StyleSheet } from "react-native";
import { isBrowser, isMobile } from "react-device-detect";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
  },
});

export default styles;

import React, { useEffect, useRef, useState } from 'react'
import { TextInput, Text, View } from 'react-native'
import { useField } from '@unform/core'
import CurrencyInput from 'react-native-currency-input';
import Colors from '../../../values/colors'
import styles from './styles'

const Input = ({ name, type, label, styleInput, styleView, styleInputView, styleText, styleError, labelType, errorMessage, ref, customGetValue, customSetValue, ...rest }) => {
    if (!labelType) labelType = "outlined" // flat, outlined

    const inputRef = useRef(null)
    const { fieldName, registerField, defaultValue, error } = useField(name)
    const [isFocus, setIsFocus] = useState(false)

    const [value, setValue] = useState("")

    const setFocus = (bool) => {
        setIsFocus(bool)
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,//.current,
            // path: 'value',
            getValue: ref => {
                console.log("getValue", ref?.current?.value)
                if (customGetValue)
                    return customGetValue()
                return ref.current.value
            },
            setValue: (ref, value) => {
                if (customSetValue)
                    customSetValue(value)
                else
                    ref.current.value = value
            },
            clearValue: ref => {
                return ref.current.value = ''
            }
        })
    }, [fieldName, registerField])

    useEffect(() => {
        setValue(rest.defaultValue)
    }, [rest.defaultValue])

    return (
        <View style={styleView}>
            {label && labelType == "flat" && <Text
                style={[styles.label, styleText]}
            >{label}</Text>}

            <View style={[styles.textInputView, error || errorMessage || (error && isFocus) ? { borderColor: Colors.error, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { marginBottom: 8 }, isFocus ? { borderColor: Colors.primary, borderWidth: 2 } : {}, styleInputView]}>
                {label && labelType == "outlined" && <Text
                    style={[styles.labelInner, styleText]}
                >{label}</Text>}

                {type == "money" && <>
                    <CurrencyInput
                        value={value}
                        onChangeValue={(val) => {
                            setValue(val)
                            if (rest.onChange) rest.onChange(val)
                        }}
                        prefix="R$"
                        delimiter="."
                        separator=","
                        precision={2}
                        ref={inputRef}
                        defaultValue={defaultValue}
                        {...rest}
                        onFocus={() => { setFocus(true) }}
                        onBlur={() => { setFocus(false) }}
                        style={[styles.textInput, isFocus ? styles.textInput_focus : {}, labelType == "outlined" && isFocus ? { paddingTop: 15 } : {}, styleInput]}
                    />
                </>}

                <TextInput
                    ref={inputRef}
                    defaultValue={defaultValue}
                    {...rest}
                    onFocus={() => { setFocus(true) }}
                    onBlur={() => { setFocus(false) }}
                    style={["money"].includes(type) ? { display: "none" } : [styles.textInput, isFocus ? styles.textInput_focus : {}, labelType == "outlined" && isFocus ? { paddingTop: 15 } : {}, styleInput]} />
            </View>

            {(error || errorMessage) &&
                <View style={{ backgroundColor: Colors.error, padding: 5, borderBottomLeftRadius: 3, borderBottomRightRadius: 3, marginBottom: 10 }}>
                    <Text style={[{ color: '#FFF', fontSize: 12 }, styleError]}>{error || errorMessage}</Text>
                </View>
            }
        </View>
    )
}

export default Input
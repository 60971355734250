import React, { useContext } from 'react'
import { ScrollView, View, Image, Text } from 'react-native'
import { formatData } from '../../helpers/Format'
import Icon from 'react-native-vector-icons/FontAwesome5';
import { isMobile } from "react-device-detect";

// Styles
import styles from './styles'

// Contexts
import EventoContext from '../../contexts/evento';
import LightboxContext from '../../contexts/lightbox';
import AuthContext from '../../contexts/auth';
import { TouchableOpacity } from 'react-native-gesture-handler';

const Header = () => {
    const { pagina } = useContext(LightboxContext)
    const { evento } = useContext(EventoContext)
    const { pessoa, logout } = useContext(AuthContext)

    return (
        <View style={styles.container}>
            <Image
                style={styles.evento_img}
                source={{
                    uri: evento.path_imagem
                }} />

            <ScrollView style={styles.evento_resumo} horizontal={true}>
                <View>
                    <Text style={{ fontSize: 28, color: '#04AD8A', flexWrap: "wrap" }}>{evento.nome}{evento.etapas.length > 1 ? ` - ${evento.etapa.nome}` : <></>}</Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon name={'calendar'} size="24" style={{ paddingTop: 12, paddingRight: 5 }} />
                        <Text style={{ fontSize: 16, paddingTop: 10 }}>{evento.datahora_primeiro ? `${formatData(evento.datahora_primeiro, true)}` : "Data não definida"}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon name={'map-pin'} style={{ paddingTop: 12, paddingLeft: 2, paddingRight: 10 }} />
                        <Text style={{ fontSize: 16, paddingTop: 10 }}>{evento.cidade ? `${evento.cidade.nome} / ${evento.cidade.estado.sigla}` : "Local indefinido"}</Text>
                    </View>
                </View>
            </ScrollView>

            {!isMobile && (
                <View style={styles.right_rota}>
                    {pessoa && (
                        <View style={styles.user_wrapper}>
                            <Text style={styles.user_link}>{pessoa.apelido || pessoa.nome}</Text>

                            <TouchableOpacity style={{ marginLeft: 10 }} onPress={logout}>
                                <Icon name={'sign-out-alt'} style={{ color: "#04AD8A", fontSize: 18 }} />
                            </TouchableOpacity>
                        </View>
                    )}

                    <View style={styles.lightbox_rota}>
                        {[
                            "ingressos",
                            "pagamento",
                            pessoa?.face ? undefined : "reconhecimento",
                            "confirmacao"
                        ]
                            .filter(item => item != undefined)
                            .map((item, index) =>
                                item == pagina ?
                                    <Text style={styles.rota}>{index + 1}. {{
                                        ingressos: "Ingressos",
                                        pagamento: "Pagamento",
                                        reconhecimento: "Reconhecimento",
                                        confirmacao: "Confirmação"
                                    }[pagina]}</Text>
                                    : <></>
                            )}
                        {/* {pagina == "ingressos" && <Text style={styles.rota}>1. Ingressos</Text>}
                        {pagina == "pagamento" && <Text style={styles.rota}>2. Pagamento</Text>}
                        {pagina == "confirmacao" && <Text style={styles.rota}>3. Confirmação</Text>} */}
                    </View>
                </View>
            )}
        </View>
    )
}

export default Header
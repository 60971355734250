import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        //position: 'absolute',
        //top: 0,
        width: '100%',
        height: 120,
        borderBottomColor: 'rgba(102, 102, 102, 0.35)',
        borderBottomWidth: StyleSheet.hairlineWidth,
        padding: 8
    },
    evento_img: {
        width: 175,
        height: 104,
        borderRadius: 10,
        marginRight: 8
    },
    evento_resumo: {
        flexDirection: "column"
    },
    right_rota: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    lightbox_rota: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        alignContent: 'flex-end'
    },
    rota: {
        flex: 1,
        fontSize: 24,
        color: '#04AD8A',
        textAlign: 'end'
    },
    user_link: {
        fontSize: 20,
        color: '#04AD8A',
        textAlign: 'end',
    },
    user_wrapper: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
        marginRight: 10
    }
});

export default styles
import React, { useState, createContext, useEffect, useContext } from 'react'
import API from '../services/API'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [tokenAuth, setTokenAuthUse] = useState(null)
    const [pessoa, setPessoa] = useState(null)

    const [reconhecimentoFeito, setReconhecimentoFeito] = useState(false);

    useEffect(() => {
        try {
            if (localStorage?.getItem) {
                let token = localStorage?.getItem("x-access-token")
                if (token)
                    setTokenAuth(token)
            }
        } catch (error) {
            console.error(error)
        }
    }, [])

    const setTokenAuth = async (token) => {
        //console.log("setTokenAuth", token)
        setTokenAuthUse(token)
        localStorage.setItem("x-access-token", token)
        await API.setHeader("x-access-token", token)
        window.top.postMessage(JSON.stringify({ action: "ipass-lightbox-session", value: token }), "*")

        buscarPessoa()
    }

    const buscarPessoa = async () => {
        let pessoaResponse = await API.get("/pessoas/sessao")
        let pessoa = pessoaResponse.data
        setPessoa(pessoa)
        return pessoa
    }

    const logout = async () => {
        try {
            localStorage.removeItem("x-access-token")
            setTokenAuthUse(() => null)
            setPessoa(() => null)
        } catch (error) {
            throw error
        }
    }

    return (
        <AuthContext.Provider value={{
            tokenAuth, setTokenAuth,
            pessoa, setPessoa,
            logout,
            buscarPessoa,
            reconhecimentoFeito, setReconhecimentoFeito
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
import { StyleSheet, Dimensions } from 'react-native'
import { isBrowser, isMobile } from "react-device-detect";

const styles = StyleSheet.create({
    container: Object.assign({},
        isMobile ? {
            flex: 1
        } : {
            flex: 1,
            flexDirection: 'row'
        }
    ),
    divisoria1: Object.assign({},
        {
            paddingTop: 6,
            paddingLeft: 6,
            paddingBottom: 6,
            justifyContent: 'center',
            alignItems: 'center'
        }, isMobile ? {
            marginBottom: 30
        } : {
        flex: 1,
        height: '100%',
        borderRightColor: 'rgba(102, 102, 102, 0.35)',
        borderRightWidth: 1,
    }
    ),
    divisoria2: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default styles
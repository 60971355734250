import React, { useContext } from 'react'
import { View, Text, Image } from 'react-native'
import * as Animatable from 'react-native-animatable'
import Icon from 'react-native-vector-icons/FontAwesome5';
import { isMobile } from "react-device-detect";

import Cancel from '../../../assets/images/cancel.svg'

// Contexts
import LightboxContext from '../../contexts/lightbox';

const AnimatedIcon = Animatable.createAnimatableComponent(Icon)

const Erro = () => {
    const { error } = useContext(LightboxContext)

    return (
        <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            <View style={{ flex: 1, alignContent: 'center', alignItems: 'center' }}>
                <Image style={{
                    flex: 1,
                    width: 1080.0487 / (isMobile ? 4 : 2),
                    height: 748.00219 / (isMobile ? 4 : 2),
                    resizeMode: 'contain'
                }} source={{
                    uri: Cancel
                }} />

                <Text style={{ fontSize: isMobile ? 18 : 24, marginTop: 30, textAlign: "center" }}>{error.text}</Text>
            </View>
        </View>
    )
}

export default Erro
import React, { useContext } from 'react'
import { View, Text } from 'react-native'
import { Ionicons } from '@expo/vector-icons';

import { formatReal } from '../../helpers/Format'

// Styles
import styles from './styles'

// Contexts
import EventoContext from '../../contexts/evento'

const ResumoSetor = (props) => {
    const { evento, getSetor } = useContext(EventoContext)

    const getFaixaPrecoSetor = (setor_id) => {
        let setor = getSetor(setor_id)

        if (!setor) return null
        if (setor.lotes.length == 0) return null

        let menor = setor.lotes[0].valor
        let maior = setor.lotes[0].valor

        //console.log("getFaixaPrecoSetor", setor)

        setor.lotes.forEach(ingresso => {
            if (ingresso.valor < menor) menor = ingresso.valor
            if (ingresso.valor > maior) maior = ingresso.valor
        })

        if (menor == maior && menor == 0) return `GRÁTIS`

        menor = formatReal(menor)
        maior = formatReal(maior)

        return menor == maior ? menor : `${menor} - ${maior}`
    }

    return (
        <View style={props.selecionado ? styles.containerSelecionado : styles.container}>
            <Text style={{ fontSize: 20, fontWeight: 400 }}>{props.setor.nome}</Text>
            <Text style={{ fontSize: 14, color: '#04AD8A', paddingTop: 10 }}>{getFaixaPrecoSetor(props.setor._id)}</Text>

            {props.selecionado && <Ionicons name="triangle" size={36} style={styles.triangulo} />}
        </View>
    )
}

export default ResumoSetor
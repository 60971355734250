import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    label: {
        color: "#152536",
        fontSize: 14
    },
    labelInner: {
        color: "#152536",
        fontSize: 12,
        marginTop: -5,
        marginLeft: 10,
        position: 'absolute',
        backgroundColor: '#FFF',
        paddingLeft: 5,
        paddingRight: 5
    },
    textInputView: {
        borderWidth: 1,
        borderColor: "#CED4DA",
        borderRadius: 3,
        marginTop: 8,
    },
    textInput: {
        padding: 10,
        paddingTop: 15
    },
});

export default styles
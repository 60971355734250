import React from "react";

import { LightboxProvider } from "../contexts/lightbox";
import { EventoProvider } from "../contexts/evento";
import { CompraProvider } from "../contexts/compra";
import { AuthProvider } from "../contexts/auth";
import { WebSocketProvider } from "../contexts/webSocket";
import { PagamentoProvider } from "../contexts/pagamento";
import { TitularesProvider } from "../contexts/titulares";

const Providers = ({ children }) => {
  return (
    <LightboxProvider>
      <WebSocketProvider>
        <AuthProvider>
          <EventoProvider>
            <PagamentoProvider>
              <CompraProvider>
                <TitularesProvider>{children}</TitularesProvider>
              </CompraProvider>
            </PagamentoProvider>
          </EventoProvider>
        </AuthProvider>
      </WebSocketProvider>
    </LightboxProvider>
  );
};

export default Providers;

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    height: 55,
    borderTopColor: "rgba(102, 102, 102, 0.35)",
    borderTopWidth: StyleSheet.hairlineWidth,
    padding: 8,
  },
  botao: {
    borderRadius: 10,
  },
});

export default styles;
